<div class="flex gap-0.5 xl:gap-2 flex-wrap lg:flex-row items-center">
    <p class="text-secondary-500 font-bold text-sm xl:text-base">{{label}}</p>
    <p *ngIf="!blink else status" class="text-gray-700 text-sm xl:text-base p-1">{{value}}</p>
    <ng-template #status>
        <div class="p-1 rounded" [ngClass]="getBlinkColor(value)">
            <p class="animate-pulse text-sm xl:text-base uppercase">{{getStatusLabel(value)}}</p>

        </div>
    </ng-template>
</div>
