import { INewOrder } from '@core';
import { MeasureUnits, UnitPrice } from './price';

export enum Frequency {
  HOURLY = "hourly",
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  YEARLY = "yearly"
}

export enum ServiceLimits {
  LIMIT_BY_AOI = 'limit_by_aoi',
  LIMIT_BY_TOI ='limit_by_toi',
  LIMIT_BY_TOI_AND_AOI = 'limit_by_toi_and_aoi'
}

export enum PriceModel {
  BASED_ON_AOI = 'based_on_aoi',
  BASED_ON_TOI = 'based_on_toi',
  BASED_ON_TOI_AND_AOI = 'based_on_toi_and_aoi',
}

export enum Status {
  DRAFT = "DRAFT",
  REVIEW = "REVIEW",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  PUBLISHED = "PUBLISHED"
}

export enum LegalConstraints {
  COMMERCIAL = 'commercial',
  FREE = 'free',
}

export interface ServicePeriod{
  serviceStart: Date
  serviceStop: Date
}

export enum Type {
  Data_Collection = "Data_Collection",
  Data_Dissemination_Service = "Data_Dissemination_Service",
  Processing_Service = "Processing_Service",
  Data_Preparation_Service = "Data_Preparation_Service"
}

export enum Subtype {
  Direct_Download = "Direct_Download",
  "WPS_1.0" = "WPS_1.0",
  "CSW_2.0.2" = "CSW_2.0.2",
  WMS = "WMS",
  WFS = "WFS"
}

export interface Publisher {
  id: number;
  key: string
  name: string;
  publish: string;
}

export interface AssociatedService {
  name: string;
  uri: string;
  description: string
}

export interface TryAndBuyParameter {
  name: string;
  value: string;
}

export interface TimeArea {
  min: number;
  max: number;
  unit: string
}

export interface ServiceOrderLimits {
  area: TimeArea;
  time: TimeArea;
}

export interface OutputInformation {
  [key: string]: string;
}


export type Service = {
  id: number;
  aoiScale: number;
  toiScale: number;
  orderCount: number;
  identifier: string;
  title: string;
  description: string;
  fileName: string;
  inputFileTypes: string[];
  serviceId: string;
  serviceType: Type;
  serviceURI: string;
  serviceSubtype: Subtype;
  serviceCategory: Category;
  serviceDomainArea: string;
  serviceName: string;
  keywords: string[];
  publishers: Publisher[];
  isSubscription: boolean;
  isAutoExecution: boolean;
  documentationURI: string;
  priceModel: PriceModel;
  unitPrice: UnitPrice;
  measureUnits?: MeasureUnits;
  orderFee: number;
  legalConstraints: LegalConstraints;
  language: string;
  purpose: string;
  versionNumber: number;
  serviceFootprint: string;
  status: Status;
  createdBy: string;
  sampleURIs: string[];
  createdAt: Date;
  updatedAt: Date;
  serviceGroups: string[];
  wmsStyle: string;
  supportURI: string;
  thumbnailURI: string;
  customRequestPrice: string;
  shortDescription: string;
  serviceInstructions: string;
  associatedServices: AssociatedService[];
  isTryAndBuy: boolean;
  tryAndBuyParameters: INewOrder;
  serviceOrderLimits?: ServiceOrderLimits;
  outputInformation: OutputInformation;
  serviceProvider: ServiceProvider;
  servicePeriod: ServicePeriod;
  allowedGeometry?: string;
  eulaUri?: string;
  timeStep?: number[];
  timeDelay?: string;
};

export type ListOfServices = Array<Service>;

export interface ServiceParameter {
  id?: number;
  serviceId: number;
  name: string;
  description: string;
  type: string;
};

export type ServiceProvider = {
  organizationName: string;
  individualName: string;
  contactInfo: {
    emailAddress: string;
    city: string;
    country: string;
  };
  organizationURI: string;
  organizationLogoURI?: string;
};

export type ListOfCatalogueServices = Service[];

export type CataloguePayload = {
  totalResults: number;
  itemsPerPage: 18 | 32 | 48 | 64;
  services: Service[];
  loading: boolean;
};

export type Category = {
  id: number;
  slug: string;
  name: string;
  parent: DomainArea;
};

export type DomainArea = {
  id: number;
  slug: string;
  name: string;
  expanded: boolean;
  thumbnail: string;
  categories: Category[];
};

export interface ServicesFilters {
  [key: string]: number | string | boolean | null;
}

export type serviceInput = {
  minOccurs: number;
  maxOccurs: number;
  identifier: string;
  title: string;
  literalData: {
    dataType: string;
    value?: Array<number | string>;
    anyValue: boolean;
  };
};

export type serviceOutput = {
  identifier: string;
  title: string;
  dataType: string[];
};

export interface ServiceDescription {
  processDescription: {
    processVersion: number;
    storeSupported: boolean;
    statusSupported: boolean;
    identifier: string;
    title: string;
    abstractText: string;
    profile: string;
    input: serviceInput[];
    output: serviceOutput[];
  };
}

export type Collection = {
  mission: string;
  type: string;
};

export type Processor = {
  identifier: string;
  title: string;
};

export type ServiceRegistrationResponse = {
  totalInserted: number;
  totalUpdated: number;
  totalDeleted: number;
};
