import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-label-secondary-color',
  templateUrl: './label-secondary-color.html',
  styleUrls: ['./label-secondary-color.component.scss']
})
export class LabelSecondaryColorComponent implements OnInit {
  @Input() helperText: string = "";
  @Input() label!: string;
  @Input() isRequired: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}
