import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Service } from '@core';
import { NgxPermissionsService } from 'ngx-permissions';

const IMG_PLACEHOLDER = 'https://res.cloudinary.com/deimospt/image/upload/v1659628047/backgrounds/services_pvcsuf.jpg';

@Component({
  selector: 'app-service-catalogue-card',
  templateUrl: './service-catalogue-card.component.html',
  styleUrls: ['./service-catalogue-card.component.scss'],
})
export class ServiceCatalogueCardComponent implements OnInit {
  @Input() service!: Service;
  @Input() description!: string;
  @Output() onCardClick = new EventEmitter();

  hasPermission = true;

  photo: string;

  constructor(private ngxPermissionsService: NgxPermissionsService) {
    this.photo = IMG_PLACEHOLDER;
  }

  ngOnInit(): void {}

  async onClickEvent() {
    this.onCardClick.emit();
  }

  errorHandler(event: any) {
    event.target.src = IMG_PLACEHOLDER;
  }

  loadServiceImage() {
    this.photo = this.service.thumbnailURI;

    if (!this.photo){
      this.photo = this.service.serviceCategory.parent.thumbnail;
    }
  }

  cCardClasses() {
    return { "hover:cursor-pointer": this.hasPermission };
  }
}
