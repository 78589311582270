<button
    [ngClass]="isDisabledClass()"
		(click)="onClick()"
		[disabled]="isDisabled"
		[type]="type"
		class="{{
    isSecondary
      ? isDark ? 'bg-secondary-800' :
      'bg-gradient-to-r from-secondary-500 to-secondary-600'
      : isSuccess
      ? '!bg-gradient-to-r from-green-400 to-green-500'
      : isDangerous
      ? '!bg-gradient-to-r from-red-400 to-red-500'
      : isWhite
      ?
      '!bg-gradient-to-r from-gray-200 to-gray-300 !text-secondary-500 border-gray-100 border '
      :isTransparent? 'bg-transparent text-primary-500'
      : isOutline?'bg-transparent text-gray-50 border-primary-500 border':'bg-primary-500 text-gray-50 hover:bg-primary-600 duration-300'
  }}
  {{
    isFull
    ? 'w-full justify-center':''
  }}
   flex p-2 rounded transform duration-300  gap-4 text-gray-50 uppercase font-medium"
>
	<fa-icon *ngIf="leftIcon" [icon]="leftIcon" [ngClass]="iconClasses"></fa-icon>
	<span class="text-xs md:text-base">{{ label }}</span>
	<fa-icon *ngIf="rightIcon" [icon]="rightIcon" [ngClass]="iconClasses"></fa-icon>
</button>
