import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  public isPopupActive$ = new BehaviorSubject(false);

  public header: string = 'Header';
  public footer: string = 'Footer';
  public orderId?: number;

  public popupConfig = {
    deactivateAfter: 2000,
  };

  private setPopupOptions(header: string, footer: string, orderId?: number) {
    const during = (time: number) => {
      this.setPopup(header, footer, time, orderId);

      const show = () => {
        this.setSnackbarActive();
      };
      return { show };
    };

    const show = () => this.setSnackbarActive();

    return { during, show };
  }

  private setPopup(header: string, footer: string, time: number, orderId?: number) {
    this.header = header;
    this.footer = footer;
    this.orderId = orderId;
    this.popupConfig.deactivateAfter = time;
  }

  private setSnackbarActive() {
    this.isPopupActive$.next(true);
  }

  public default = (header: string, footer: string, orderId?: number) =>
    this.setPopupOptions(header, footer, orderId).during(200).show();
}
