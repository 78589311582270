import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService, Order, OrderStatus, OrdersServices, PaginationService, PaymentMethod, PaymentPayload, PaymentService, PriceInfo, Service, ServicesService, SnackbarService, TranslateService, paymentMethods } from '@core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faGlobeEurope,
  faTimesCircle,
  faSpinner,
  faToggleOn,
  faToggleOff
} from '@fortawesome/free-solid-svg-icons';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable, Subject, forkJoin, take, takeUntil } from 'rxjs';

type OrdersLoaderParams = {
  perPage: number;
  page: number;
};

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss'],
})
export class MyOrdersComponent implements OnInit, OnDestroy {
  id: string | null = null;

  orders: Order[] = [];
  pagination$ = new BehaviorSubject<OrdersLoaderParams>({
    page: 1,
    perPage: 18,
  });
  componentDestroyed$: Subject<boolean> = new Subject();

  isLoading: boolean = true;
  noOrders: boolean = false;
  noOrdersIcon: IconDefinition = faGlobeEurope;
  hasError: boolean = false;
  hasErrorIcon: IconDefinition = faTimesCircle;
  faSpinner = faSpinner;
  faToggleOn = faToggleOn;
  faToggleOff = faToggleOff;

  isBulkPaymentReady = false;
  isBulkPaymentConfirmed = false;
  isLoadingBulkPayment = false;
  isProcessingPayment = false;
  bulkPriceTotal?: string;

  paymentMethods = [paymentMethods[0]];
  selectedPayment: string;

  serviceOrderMap: {[orderId: number]: Service} = {};
  orderPriceMap: {[oderId: string]: number} = {};

  subscriptionsFiltered: boolean = false;

  constructor(
    private ordersService: OrdersServices,
    private paginationService: PaginationService,
    private oidcSecurityService: OidcSecurityService,
    private paymentService: PaymentService,
    private servicesService: ServicesService,
    private snackbarService: SnackbarService,
    private translateService: TranslateService,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.paginationService.reset();
  }

  ngOnInit(): void {
    this.loadOrders();
    this.analyticsService.emitPageViewEvent('user_orders', '/customers/orders');
  }

  loadOrders() {
    this.pagination$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.ordersService
          .getAllUserOrders(false, this.subscriptionsFiltered, data.perPage, data.page)
          .pipe(take(1))
          .subscribe((data) => {
            this.paginationService.setTotalResults = data.total;
            this.paginationService.setItemsPerPage = data.perPage as
              | 18
              | 32
              | 48
              | 64;
            this.orders = data.rows;
            this.noOrders = data.total == 0;
            this.isLoading = false;
          });
      });
  }

  onPageChange() {
    this.pagination$.next({
      page: this.paginationService.getCurrentPage,
      perPage: this.paginationService.getItemsPerPage,
    });
  }

  onPerPageChange() {
    this.paginationService.setCurrentPage = 1;
    this.pagination$.next({
      page: this.paginationService.getCurrentPage,
      perPage: this.paginationService.getItemsPerPage,
    });
  }

  get pageTotalResults() {
    return this.paginationService.getTotalResults;
  }
  
  get paymentPendingOrders(): Order[] {
    return this.orders ? 
      this.orders.filter(order => order.status === OrderStatus.PAYMENT_PENDING)
      : [];
  }

  onPayAllClick() {
    this.serviceOrderMap = {};
    this.isLoadingBulkPayment = true;
    const pendingOrders = this.paymentPendingOrders;
    this.oidcSecurityService.getUserData().pipe(take(1))
    .subscribe({
      next: (userData) => {

        const serviceRequests: {[x: number]: Observable<Service>} = {}
        pendingOrders.map(
          order => {
            serviceRequests[order.id] = this.servicesService.getServiceByIdentifier(order.serviceId);
          }
        );

        forkJoin(serviceRequests).pipe(take(1)).subscribe({
          next: (servicesResult) => {
            const paymentInfoRequests: {[x: number]: Observable<PriceInfo>} = {}
            pendingOrders.forEach(order => {
              this.serviceOrderMap[order.id] = servicesResult[order.id];
            });
            pendingOrders.map(
              order => {
                paymentInfoRequests[order.id] = this.getPaymentInfo(order, servicesResult[order.id], userData);
              }
            );
            
            forkJoin(paymentInfoRequests).pipe(take(1)).subscribe({
              next: (paymentInfoResult) => {
                const orderIds = Object.keys(paymentInfoResult);
                let priceTotal = 0;
                orderIds.forEach(orderId => {
                  priceTotal += paymentInfoResult[parseInt(orderId, 10)].totalPrice;
                  this.orderPriceMap[orderId] = paymentInfoResult[parseInt(orderId, 10)].totalPrice;
                });
                if (priceTotal > 0) {
                  this.isBulkPaymentReady = true;
                  this.bulkPriceTotal = priceTotal.toFixed(2);
                  this.isLoadingBulkPayment = false;
                }
              },
              error: () => this.isLoadingBulkPayment = false
            })
          },
          error: () => this.isLoadingBulkPayment = false
        });
      },
      error: () => this.isLoadingBulkPayment = false
    })
  }

  getPaymentInfo(order: Order, service: Service, userData: any): Observable<PriceInfo> {
      return this.paymentService
      .calculatePrice(
        order as Order,
        service as Service,
        (userData.originCountry as string) ?? 'PT',
        userData.vatPercentage ? parseFloat(userData.vatPercentage) : undefined
      );
  }

  onBulkPaymentClose() {
    this.isBulkPaymentReady = false;
  }

  confirmBulkPayment() {
    this.isBulkPaymentReady = false;
    this.isBulkPaymentConfirmed = true;
  }

  onPaypalDialogCancel() {
    this.isBulkPaymentConfirmed = false;
    this.bulkPriceTotal = undefined;
  }

  onPaymentApproved(payload: PaymentPayload) {
    this.isProcessingPayment = true;
    this.isBulkPaymentConfirmed = false;
    this.bulkPriceTotal = undefined;

    this.snackbarService.success(
      this.translateService.translate('bulk-payment.snackbar.submission.title'),
      this.translateService.translate('bulk-payment.snackbar.submission.message')
    ).during(2000).show();

    const orderIds = this.paymentPendingOrders.map(order => order.id);
    this.ordersService.generateBulkPayment(orderIds, payload.reference)
    .pipe(take(1)).subscribe({
      next: () => {
        this.analyticsService.emitPaymentEvent('bulk-payment', 'paypal');
        this.snackbarService.success(
          this.translateService.translate('bulk-payment.snackbar.success.title'),
          this.translateService.translate('bulk-payment.snackbar.success.message')
        ).during(4000).show();
        setTimeout(() => {
          this.isProcessingPayment = false;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/customers/orders']);
        }, 4000);
      },
      error: (e) => {
        console.error(e);
        this.analyticsService.emitFailedPaymentEvent('bulk-payment', 'paypal');
        this.isProcessingPayment = false;
        this.snackbarService.danger(
          this.translateService.translate('bulk-payment.snackbar.error.title'),
          this.translateService.translate('bulk-payment.snackbar.error.message')
        ).during(4000).show();
      }
    })
  }

  onPaymentClick(slug: PaymentMethod["slug"]) {
    this.selectedPayment = slug;
  }

  filterSubscriptionOrders() {
    this.subscriptionsFiltered = !this.subscriptionsFiltered;
    this.orders = [];
    this.isLoading = true;
    this.loadOrders();
  }
}
