import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import * as pkg from '../../../../../../package.json';
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { AuthService } from '@core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  env = environment;
  year = new Date().getFullYear();
  pkg = pkg
  faTwitter: IconDefinition = faTwitter;
  faLinkedinIn: IconDefinition = faLinkedinIn
  faYoutube: IconDefinition = faYoutube

  constructor(private authService: AuthService) {}

  ngOnInit(): void {}

  get isLoggedIn() {
    return this.authService.isLoggedIn;
  }
}
