import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-instructions',
  templateUrl: './service-instructions.component.html',
  styleUrls: ['./service-instructions.component.scss']
})
export class ServiceInstructionsComponent implements OnInit {

  @Input() instructions: string;
  arrayInstructions:string[];

  constructor() { }

  ngOnInit(): void {


    
      }   
  }

