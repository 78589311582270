import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { OutputInformation, ServiceProvider, Type } from '@core/models';

import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-service-products-specification',
  templateUrl: './service-products-specification.component.html',
  styleUrls: ['./service-products-specification.component.scss'],
})
export class ServiceProductsSpecificationComponent implements OnInit, OnChanges {
  @Input() outputInformation?: OutputInformation;
  


  constructor() {
  }

  ngOnInit(): void {
  }


  ngOnChanges(): void {}

  get outputInformationKeys(): string[]{
    if(this.outputInformation){
      return Object.keys(this.outputInformation);
    }else{
      return [];
    }
  }

}
