<div>
  <app-label-secondary-color [label]="label"></app-label-secondary-color>
  <div *ngIf=organizationLogoURI class="mt-2">
    <img src="{{organizationLogoURI}}" alt="{{textData}}" class="rounded-lg object-fill h-24 drop-shadow-sm border">
  </div>
  <div *ngIf=!organizationLogoURI>
    <p [ngClass]="textDataClass">{{textData}}</p>
  </div>
  <ng-content></ng-content>
</div>
