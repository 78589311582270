import { CommonModule, registerLocaleData } from '@angular/common';
import localeEn from "@angular/common/locales/en";
import { NgModule } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxPayPalModule } from "ngx-paypal";
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  ButtonComponent,
  CardSkeletonComponent,
  CategoryItemComponent,
  CheckboxInputComponent,
  CustomSelectInputComponent,
  DatePickerComponent,
  DialogComponent,
  DynamicFormComponent,
  DynamicInputAOIComponent,
  DynamicInputComponent,
  DynamicInputTOIComponent,
  EmptyPageComponent,
  FooterComponent,
  HeaderComponent,
  HelperTextComponent,
  IconButtonComponent,
  InputComponent,
  InputFileComponent,
  KeywordTagComponent,
  LabelSecondaryColorComponent,
  LoginCardComponent,
  MainLayoutComponent,
  MapComponent,
  MapModalComponent,
  MultipleSelectInputComponent,
  NavBarComponent,
  NavbarLinkComponent,
  PageNotFoundComponent,
  PaginationComponent, PayPalCheckoutComponent, PillsComponent,
  PopupComponent,
  ProprietyWrapperComponent,
  SearchBarComponent,
  RatingInputComponent,
  SelectComponent,
  SelectInputComponent,
  ServiceCardComponent,
  ServiceCatalogueCardComponent,
  SnackbarComponent,
  TabComponent,
  TagInputComponent,
  TextAreaComponent,
  ToiPickerComponent,
  LoadingComponent,
  MapThumbnailComponent,
  AlertWindowComponent,
  DynamicTooltipComponent,
  DynamicRangeInputComponent
} from './components';
import { OutsideClickDirective, RegexInputDirective } from './directives';
import { DateDistancePipe, ParseDatePipe, TruncatePipe, WithLoadingPipe } from './pipes';
import { LottieModule } from 'ngx-lottie';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CkeEditorComponent } from './components/io/form/cke-editor/cke-editor.component';
import { WmsLayerInfoComponent } from './components/ui/wms-layer-info/wms-layer-info.component';
import { CheckboxSelectComponent } from './components/io/checkbox-select/checkbox-select.component';
import { ToggleSwitchComponent } from './components/io/toggle-switch/toggle-switch.component';

registerLocaleData(localeEn, 'en');

@NgModule({
    declarations: [
        MainLayoutComponent,
        NavBarComponent,
        ButtonComponent,
        NavbarLinkComponent,
        TextAreaComponent,
        InputFileComponent,
        InputComponent,
        SelectInputComponent,
        FooterComponent,
        IconButtonComponent,
        HeaderComponent,
        SnackbarComponent,
        ServiceCardComponent,
        KeywordTagComponent,
        CheckboxInputComponent,
        DialogComponent,
        ServiceCatalogueCardComponent,
        MapComponent,
        DatePickerComponent,
        PaginationComponent,
        SelectComponent,
        HelperTextComponent,
        SearchBarComponent,
        CategoryItemComponent,
        OutsideClickDirective,
        DynamicInputComponent,
        DynamicFormComponent,
        DynamicInputTOIComponent,
        DynamicInputAOIComponent,
        LoginCardComponent,
        WithLoadingPipe,
        TruncatePipe,
        DateDistancePipe,
        ParseDatePipe,
        CardSkeletonComponent,
        TabComponent,
        TagInputComponent,
        RegexInputDirective,
        MultipleSelectInputComponent,
        ToiPickerComponent,
        PillsComponent,
        LabelSecondaryColorComponent,
        ProprietyWrapperComponent,
        MapModalComponent,
        CustomSelectInputComponent,
        PageNotFoundComponent,
        EmptyPageComponent,
        PopupComponent,
        PayPalCheckoutComponent,
        RatingInputComponent,
        LoadingComponent,
        DynamicTooltipComponent,
        MapThumbnailComponent,
        DynamicRangeInputComponent,
        AlertWindowComponent,
        CkeEditorComponent,
        WmsLayerInfoComponent,
        CheckboxSelectComponent,
        ToggleSwitchComponent
    ],
    imports: [
        CommonModule,
        LeafletModule,
        RouterModule,
        NgxPermissionsModule,
        FormsModule,
        FontAwesomeModule,
        AngularSvgIconModule,
        ReactiveFormsModule,
        AngularMyDatePickerModule,
        NgSelectModule,
        TranslateModule,
        NgxPayPalModule,
        LottieModule,
        CKEditorModule
    ],
    exports: [
        CommonModule,
        LeafletModule,
        RouterModule,
        MainLayoutComponent,
        AngularSvgIconModule,
        HeaderComponent,
        InputComponent,
        SelectInputComponent,
        DynamicInputComponent,
        DynamicInputTOIComponent,
        DynamicInputAOIComponent,
        TextAreaComponent,
        ButtonComponent,
        InputFileComponent,
        SnackbarComponent,
        ServiceCardComponent,
        CheckboxInputComponent,
        DialogComponent,
        ServiceCatalogueCardComponent,
        MapComponent,
        DatePickerComponent,
        PaginationComponent,
        SelectComponent,
        HelperTextComponent,
        SearchBarComponent,
        CategoryItemComponent,
        IconButtonComponent,
        DynamicFormComponent,
        LoginCardComponent,
        CardSkeletonComponent,
        TabComponent,
        TagInputComponent,
        WithLoadingPipe,
        TruncatePipe,
        DateDistancePipe,
        ParseDatePipe,
        TranslateModule,
        MultipleSelectInputComponent,
        ProprietyWrapperComponent,
        CustomSelectInputComponent,
        FontAwesomeModule,
        EmptyPageComponent,
        PopupComponent,
        PayPalCheckoutComponent,
        RatingInputComponent,
        LoadingComponent,
        MapThumbnailComponent,
        AlertWindowComponent,
        CkeEditorComponent
    ],
})
export class SharedModule {
}
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
