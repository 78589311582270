<app-wms-layer-info 
    class="w-[19rem] left-0 md:top-[10.5rem] lg:w-auto lg:absolute lg:left-1/3 lg:top-0 flex flex-col z-50"
>
</app-wms-layer-info>

<div class="z-0 h-56 w-full lg:h-screen" id="map"></div>


<div class="w-full lg:absolute lg:bottom-5 lg:right-3 flex flex-col gap-2 bg-white rounded-lg px-2 py-2 lg:w-52" *ngIf="multiFootprint">
    <div class="flex flex-row justify-center">
        <span class="font-bold m-auto text-primary-500 text-center">{{ 'map.footprint-browser.title' | translate }}</span>
    </div>  
    <div class="flex flex-row gap-4 justify-center">
        <app-icon-button
            [icon]="faMinus"
            (btnClick)="onFootprintFocusChange(-1)"
            [isDisabled]="focusedFootprintIndex === 0"
        ></app-icon-button>
        <span class="font-bold my-auto">{{ focusedFootprintIndex + 1}}</span>
        <app-icon-button
            [icon]="faPlus"
            (btnClick)="onFootprintFocusChange(1)"
            [isDisabled]="!numberOfFootprints || (focusedFootprintIndex === numberOfFootprints - 1)"
        ></app-icon-button>
    </div>

</div>
