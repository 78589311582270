import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AnalyticsService, IOrderJob, Order, OrderInputs, OrdersServices, OrderStatus, PaymentMethod, paymentMethods, PaymentMethodSlug, PaymentPayload, PaymentService, PriceInfo, ProductMetadata, Service, ServicesService } from '@core';
import { format, parseISO } from 'date-fns';
import { Subject, timer } from "rxjs";

import { ActivatedRoute } from "@angular/router";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp, faSmile, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { saveAs } from 'file-saver';
import { take, takeUntil, tap } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { parseFromWK } from 'wkt-parser-helper';

interface OrderPropertyItem { label: string, value?: string, blink?: boolean }

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss'],
  animations: [
    trigger('Details', [
      transition(':enter', [
        style({
          height: '0',
          opacity: '0',
        }),
        animate(
          '400ms ease-in-out',
          style({
            height: '*',
            opacity: '100',
          }),
        ),
      ]),
      transition(':leave', [
        animate(
          '400ms ease-in-out',
          style({
            height: '0',
            opacity: '0',
          }),
        ),
      ]),
    ]),
  ],
})
export class OrderItemComponent implements OnInit, OnDestroy {
  OrderStatus = OrderStatus;
  @Input() order?: Order;
  @Input() isLoading: boolean = false;
  @Input() disablePaymentButton: boolean = false;
  @Output() viewOrder = new EventEmitter();
  id: string | null = null;
  service?: Service;
  orderInputs: OrderInputs;
  orderBegin?: string;
  orderEnd?: string;
  orderCreated?: string;
  photo: string = 'https://res.cloudinary.com/deimospt/image/upload/v1659628047/backgrounds/pexels-denis-ovsyannikov-2724241_1_sasvn6.png';
  thumbnail: string | undefined;
  isShowDetails: boolean = false;
  isPaymentReady: boolean = false;
  isPriceLoading: boolean = false;
  isProcessingPayment: boolean = false;
  isShowingMap: boolean = false;
  hasNewOutputs: boolean = false;

  paymentMethods = paymentMethods;

  chevronDown: IconDefinition = faChevronDown;
  chevronUp: IconDefinition = faChevronUp;
  faSmile: IconDefinition = faSmile;
  faSpinner: IconDefinition = faSpinner;
  orderJobs: IOrderJob[] = [];
  source = timer(0, 60000);
  componentDestroyed$: Subject<boolean> = new Subject();

  selectedPayment?: PaymentMethodSlug = PaymentMethodSlug.PAYPAL;
  payment?: PriceInfo;

  metadataProductIdentifier?: string;
  metadataInformation: ProductMetadata;
  isMetadataDialogOpen: boolean;
  isMetadataLoading: boolean;

  constructor(
    private shopService: ServicesService,
    private activatedRoute: ActivatedRoute,
    public ordersServices: OrdersServices,
    private paymentService: PaymentService,
    private oidcSecurityService: OidcSecurityService,
    private ordersService: OrdersServices,
    private analyticsService: AnalyticsService
  ) {}

  orderPropertyItems: OrderPropertyItem[] = []

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((data) => (this.id = data.id));
    if (this.order) {
      this.orderCreated = format(
        parseISO(this.order.createdAt.toString()),
        'dd/MM/yyyy, HH:mm',
      );
      if (this.order.beginAt) {
        this.orderBegin = format(
          parseISO(this.order.beginAt.toString()),
          'dd/MM/yyyy',
        );
        this.orderEnd = format(
          parseISO(this.order.endAt.toString()),
          'dd/MM/yyyy',
        );
      }
      this.shopService
        .getServiceByIdentifier(this.order.serviceId)
        .pipe(
          take(1),
          tap((service) => {
            this.service = service;
            this.loadServiceImage();
            this.getPaymentInfo();
          })
        )
        .subscribe();

      this.orderPropertyItems = this.addOrderPropertyItems();
      
      this.ordersService.orderMapToggled$
      .pipe(takeUntil(this.componentDestroyed$)).subscribe((orderId) => {
        if (this.order && orderId !== this.order.id) {
          this.isShowingMap = false;
        }
      });

      this.hasNewOutputs = this.order.newOutputs ?? false;
    }
    
  }

  get isPendingPayment() {
    return this.order?.status === OrderStatus.PAYMENT_PENDING
  }

  downloadAll(orderId: number) {
    this.ordersServices.downloadAllOrderResults(orderId).pipe(take(1)).subscribe((blob) => saveAs(blob, orderId + '_download'));
  }

  addOrderPropertyItems(): OrderPropertyItem[] {
    return [
      {
        label: 'labels.id',
        value: this.order?.id.toString(),
      },
      {
        label: 'labels.status',
        value: this.order?.status.toString(),
        blink: true,
      },
      {
        label: 'labels.created-at',
        value: this.orderCreated,
      },
      {
        label: 'labels.begins-at',
        value: this.orderBegin,
      },
      {
        label: 'labels.ends-at',
        value: this.orderEnd,
      }
    ];
  }

  onPaymentClick(slug: PaymentMethod["slug"]) {
    this.selectedPayment = slug;
  }

  onPaymentReadyClick() {
    this.isPaymentReady = true;
    this.isProcessingPayment = true;
    this.oidcSecurityService.getUserData().pipe(
      take(1)
    ).subscribe((userData) => {
      if (this.payment){
        this.handleBetaUser(this.payment, userData, this.order?.isSubscription);
      }
    });  
  }

  paypalApprove(payload: PaymentPayload, isSubscription?: boolean) {
    this.ordersServices
      .generatePayment(payload, this.order?.id, isSubscription)
      .subscribe(() => {
        this.isPaymentReady = false;
        this.isProcessingPayment = false;
        this.analyticsService.emitPaymentEvent(this.service?.identifier ?? 'unknown-service-id', 'paypal');
      });
  }

  onDialogCancel() {
    this.isPaymentReady = false;
    this.isPriceLoading = false;
    this.isProcessingPayment = false;
  }

  errorHandler(event: any) {
    event.target.src = this.photo;
  }

  loadServiceImage() {
    this.thumbnail = this.service?.thumbnailURI;

    if (!this.thumbnail){
      this.thumbnail = this.service?.serviceCategory.parent.thumbnail;
    }
  }

  handleBetaUser(payment: PriceInfo, userData: any, isSubscription?: boolean) {    
  
    if (userData && userData.beta) {
      this.paypalApprove({
        reference: 'beta_user'
      }, isSubscription);
      this.isPaymentReady = false;
    }
  }

  getPaymentInfo() {
    if (this.isPendingPayment) {
      this.isPriceLoading = true;

      this.oidcSecurityService.getUserData().pipe(take(1))
      .subscribe((userData) => {
        this.paymentService
        .calculatePrice(
          this.order as Order,
          this.service as Service,
          (userData.originCountry as string) ?? 'PT',
          userData.vatPercentage ? parseFloat(userData.vatPercentage) : undefined
        )
        .subscribe(
          {
            next: (payment) => {
              this.payment = payment;
            },
            complete: () => (this.isPriceLoading = false),
          }
        );
      });
    }
  }

  get userData$() {
    return this.oidcSecurityService.getUserData();
  }

  toggleAOIMap() {
    this.isShowingMap = !this.isShowingMap;
  }

  get orderAOIGeoJSON() {
    if (this.order && this.order.aoi)
      return parseFromWK(this.order.aoi);
    else
      return undefined;
  }

  emitMapDestroyed() {
    if (this.order)
      this.ordersService.orderMapToggled$.next(this.order.id);
  }

  viewMetadataClicked(productIdentifier: string) {
    this.isMetadataLoading = true;
    this.ordersService.getProductMetadata(productIdentifier).pipe(take(1))
    .subscribe({
      next: (metadata) => {
        this.metadataProductIdentifier = productIdentifier;
        this.isMetadataDialogOpen = true;
        this.metadataInformation = metadata;
        this.isMetadataLoading = false;
      },
      error: () => { this.isMetadataLoading = false; }
    })

  }

  onMetadataClose() {
    this.isMetadataDialogOpen = false;
    this.metadataProductIdentifier = undefined;
  }

  toggleOutputDisplay() {
    this.isShowDetails = !this.isShowDetails;
    if (this.hasNewOutputs && this.isShowDetails) {
      this.hasNewOutputs = false;
    }
  }
}
