<div
  class="max-w-sm bg-white border border-gray-200 rounded-lg shadow flex flex-1 flex-col h-full max-h-[40rem]"
  (click)="onClickEvent()"
  [ngClass]="'hover:cursor-pointer'"
>
  <div class="relative">
    <span
      *ngIf="service.serviceCategory"
      class="absolute z-20 bottom-2 right-2 px-2 py-1 leading-none bg-secondary-300 text-secondary-600 rounded-md font-semibold uppercase tracking-wide text-xs drop-shadow-lg"
    >
      {{ service.serviceCategory.parent.name }}
    </span>

    <img
      (error)="errorHandler($event)"
      (load)="loadServiceImage()"
      [src]="photo"
      class="rounded-t-lg contrast-100 w-full h-[150px] lg:h-[180px] object-fill"
      alt="service thumbnail"
    />
  </div>

  <div class="p-5 flex flex-col h-[100%]">
    <div>
      <span
        *ngIf="service.serviceCategory"
        class="inline-block px-2 py-1 leading-none bg-primary-300 text-primary-600 rounded-md font-semibold uppercase tracking-wide text-xs"
        >{{ service.serviceCategory.name }}</span
      >
    </div>

    <div class="grow">
      <h2 class=" mt-4 font-bold break-words">
        {{ service.title }}
      </h2>
      
      <p *ngIf="service.versionNumber !== null" class="mb-4 text-gray-600 font-bold text-sm">
        {{ "Version " +service.versionNumber}}
      </p>
     
      <p class="text-sm break-words">
        {{ service.description | truncate : 120 }}
      </p>
    </div>

    <hr class="mb-2 border-gray-200" />
    <div class="text-xs text-gray-700 flex flex-wrap gap-2">
      <ng-container *ngFor="let keyword of service.keywords">
        <span class="flex items-center mb-1 underline font-medium">
          {{ keyword }}
        </span>
      </ng-container>
    </div>
    <hr class="mt-2 border-gray-200" />

    <div class="flex items-center text-sm text-gray-600 mt-6">
      <span
        >Provided by
        <span class="font-bold">{{
          service.serviceProvider ? service.serviceProvider.organizationName : ''
        }}</span>
      </span>
    </div>
  </div>
</div>
