import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { TranslateService } from '@core';
import { SnackbarService } from '@core/services';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnauthorizedLoadGuard implements CanLoad {
  constructor(
    private permissionsGuard: NgxPermissionsGuard,
    private snackbarService: SnackbarService,
    private translateService: TranslateService
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const loaded = this.permissionsGuard.canLoad(route);

    (<Promise<boolean>>loaded)
      .then((load) => {
        const title = 'unauthorized.not-authorized';
        const message = 'unauthorized.forbidden-access';

        if (!load) {
          this.translateService
            .get([title, message])
            .subscribe((data) =>
              this.snackbarService
                .danger(data[title], data[message])
                .during(4000)
                .show()
            );

          return false;
        }
        return true;
      })
      .catch(() => {
        return false;
      });

    return true;
  }
}
