import { Validators } from "@angular/forms"

export const advancedSearchForm = {
  location:[null],
  aoi:[null, Validators.pattern(/-?(?:\.\d+|\d+(?:\.\d*)?)/gm)],
  beginDate:[null],
  endDate:[null]
}

export const searchForm = {
  search:[null]
}
