import { EventEmitter } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { Component, OnInit, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CMSResponse, CmsService, IServiceEvaluation } from "@core";
import { dateFormat } from "../../../../../../shared"

@Component({
  selector: 'app-evaluations',
  templateUrl: './evaluations.component.html',
  styleUrls: ['./evaluations.component.scss']
})
export class EvaluationsComponent implements OnInit {
  userData = this.oidcSecurityService.getUserData() as any;
  serviceId: string = this.activatedRoute.snapshot.paramMap.get('id') as string;
  serviceEvaluations!: IServiceEvaluation;
  serviceEvaluations$?: Observable<CMSResponse<IServiceEvaluation[]>>
  dateFormat = dateFormat
  userName: any;
  evaluationForm: FormGroup = this.formBuilder.group(
    {
      rating: [1, Validators.required],
      comment: [null, Validators.required],
      serviceId: [this.serviceId],
    }
  );
  userHasComment: boolean = false;
  @Input() total: number = 0;
  @Output() totalChange = new EventEmitter();


  constructor(
    private cmsService: CmsService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private oidcSecurityService: OidcSecurityService
  ) {}

  ngOnInit(): void {
    this.serviceEvaluations$ = this.cmsService.getServiceEvaluations(this.serviceId)

    this.serviceEvaluations$?.subscribe(
      response => {
        this.total = response.meta.pagination.total;
        this.totalChange.emit(this.total)
        this.userHasComment = response.data.some(a => a.attributes.inum.includes(this.userData.inum))
      }
    )
  }

  

  getInitials(name: string): string {
    const initials = name.split(' ');
    if (initials.length >= 2) {
        return (
            initials[0].charAt(0).toUpperCase() +
            initials[1].charAt(0).toUpperCase()
        );
    } else {
        return initials[0].charAt(0).toUpperCase();
    }
  }

  evaluationRatingConvertToArray(lenght: number): any[] {
    return Array.from(Array(lenght).keys())
  }

  onSubmit(e: Event) {
    e.preventDefault();
    
    this.cmsService.storeServiceEvaluation(this.evaluationForm.value)
      .subscribe({
        next: payload => {
          this.serviceEvaluations$ = this.cmsService.getServiceEvaluations(this.serviceId);
          this.evaluationForm.reset();
          this.userHasComment = true;

          this.total += 1;
          this.totalChange.emit(this.total)
        }
      })

  }

}
