import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  AreaOfInterestInput, DropdownInput,
  DynamicInputBase,
  slugify,
  TextboxInput,
  TimeOfInterestInput
} from 'shared';
import { serviceInput, ServicePeriod, Type } from '../../models';
import { ServicesService } from '../services/services.service';

@Injectable()
export class InputService {
  constructor(private geoRecordServices: ServicesService) {
  }

  isLoading: boolean = false;

  getInputs(
    geoRecordIdentifier: string,
    geoRecordType?: Type,
    servicePeriod?: ServicePeriod,
    serviceFootprint?: string,
    isAuthenticated?: boolean
  ) {
    const serviceType = geoRecordType ?? undefined;

    let dynamicInputs: Array<DynamicInputBase<any>> = [];
    dynamicInputs = this.getInputsFromServer(
      serviceType,
      geoRecordIdentifier,
      servicePeriod,
      serviceFootprint,
      isAuthenticated
    );
    return of(dynamicInputs);
  }

  getInputsFromServer(
    serviceType: Type | undefined,
    geoRecordIdentifier: string,
    servicePeriod: ServicePeriod | undefined,
    serviceFootprint?: string,
    isAuthenticated?: boolean
  ) {
    let dynamicInputs: Array<DynamicInputBase<any>> = [];

    switch (serviceType) {
      case Type.Processing_Service:
      case Type.Data_Preparation_Service:
        this.isLoading = true;
        if (isAuthenticated) {
          this.geoRecordServices
          .getServiceDescription(geoRecordIdentifier)
          .pipe(take(1))
          .subscribe(
            (description) => {
              let aoiInput = new AreaOfInterestInput({
                key: 'aoi',
                label: 'Area of Interest',
                required: true,
                type: 'string',
                value: '',
                order: 1,
                serviceFootprint: serviceFootprint,
              });
              let toiStartDateInput = new TimeOfInterestInput({
                key: 'toiStartDate',
                label: 'main-page.orders.time-of-interest-start',
                required: true,
                type: 'date',
                value: '',
                order: 1,
                serviceStartDate: servicePeriod?.serviceStart,
                serviceEndDate: servicePeriod?.serviceStop,
              });
              let toiEndDateInput = new TimeOfInterestInput({
                key: 'toiEndDate',
                label: 'main-page.orders.time-of-interest-end',
                required: true,
                type: 'date',
                value: '',
                order: 1,
                serviceStartDate: servicePeriod?.serviceStart,
                serviceEndDate: servicePeriod?.serviceStop,
              });
              dynamicInputs.push(aoiInput, toiStartDateInput, toiEndDateInput);
              description.processDescription.input.map((input, index) => {
                if (
                  input.identifier.includes('START_TIME_PARAMETER') ||
                  input.identifier.includes('STOP_TIME_PARAMETER')
                ) {
                  input.literalData.dataType = 'date';
                }
                if (
                  !input.identifier.includes('TRIGGERING') &&
                  !input.identifier.includes('AOI')
                ) {
                  dynamicInputs.push(this.handleExtraInputs(input, index));
                }
              });
            },
            () => {
            },
            () => (this.isLoading = false)
          );
        } else {
          let aoiInput = new AreaOfInterestInput({
            key: 'aoi',
            label: 'main-page.orders.area-of-interest',
            required: true,
            type: 'string',
            value: '',
            order: 1,
            serviceFootprint: serviceFootprint,
          });
          let toiStartDateInput = new TimeOfInterestInput({
            key: 'toiStartDate',
            label: 'main-page.orders.time-of-interest-start',
            helperText: 'main-page.orders.select-date-range-start',
            required: true,
            type: 'date',
            value: '',
            order: 1,
            serviceStartDate: servicePeriod?.serviceStart,
            serviceEndDate: servicePeriod?.serviceStop,
          });
          let toiEndDateInput = new TimeOfInterestInput({
            key: 'toiEndDate',
            label: 'main-page.orders.time-of-interest-end',
            helperText: 'main-page.orders.select-date-range-end',
            required: true,
            type: 'date',
            value: '',
            order: 1,
            serviceStartDate: servicePeriod?.serviceStart,
            serviceEndDate: servicePeriod?.serviceStop,
          });
  
          dynamicInputs.push(aoiInput, toiStartDateInput, toiEndDateInput);
        }
        
        break;

      default:
        let aoiInput = new AreaOfInterestInput({
          key: 'aoi',
          label: 'main-page.orders.area-of-interest',
          required: true,
          type: 'string',
          value: '',
          order: 1,
          serviceFootprint: serviceFootprint,
        });
        let toiStartDateInput = new TimeOfInterestInput({
          key: 'toiStartDate',
          label: 'main-page.orders.time-of-interest-start',
          helperText: 'main-page.orders.select-date-range-start',
          required: true,
          type: 'date',
          value: '',
          order: 1,
          serviceStartDate: servicePeriod?.serviceStart,
          serviceEndDate: servicePeriod?.serviceStop,
        });
        let toiEndDateInput = new TimeOfInterestInput({
          key: 'toiEndDate',
          label: 'main-page.orders.time-of-interest-end',
          helperText: 'main-page.orders.select-date-range-end',
          required: true,
          type: 'date',
          value: '',
          order: 1,
          serviceStartDate: servicePeriod?.serviceStart,
          serviceEndDate: servicePeriod?.serviceStop,
        });

        dynamicInputs.push(aoiInput, toiStartDateInput, toiEndDateInput);
    }

    return dynamicInputs;
  }

  handleExtraInputs(
      input: serviceInput,
      index: number
  ): DynamicInputBase<string> {
    if (
        !input.literalData.anyValue &&
        input.literalData.value &&
        input.literalData.value?.length > 0
    ) {
      let options: {
        key: string;
        value: string;
      }[] = [];
      input.literalData.value.map((value) => {
        options.push({
          key: value as string,
          value: value as string,
        });
      });
      return new DropdownInput({
        key: input.identifier,
        label: input.title,
        required: input.minOccurs == 1,
        options,
        order: index,
      });
    } else {
      return new TextboxInput({
        key: input.identifier,
        label: input.title,
        required: input.minOccurs == 1,
        order: index,
      });
    }
  }
}

//  = [
//   new DropdownInput({
//     key: 'brave',
//     label: 'Bravery Rating',
//     options: [
//       {key: 'solid', value: 'Solid'},
//       {key: 'great', value: 'Great'},
//       {key: 'good', value: 'Good'},
//       {key: 'unproven', value: 'Unproven'},
//     ],
//     order: 3,
//   }),
//
//   new TextboxInput({
//     key: 'firstName',
//     label: 'First name',
//     value: 'Bombasto',
//     required: true,
//     order: 1,
//   }),
//
//   new TextboxInput({
//     key: 'emailAddress',
//     label: 'Email',
//     type: 'email',
//     order: 2,
//   }),
// ];
