<div class="relative">
  <app-input
    #appInput
    [maxLength]="90"
    [inputClass]="valueExistsClass()"
    [regex-pattern]="regexPattern"
    [placeholder]="placeholder"
    [required]="required"
    autocomplete="off"
    [label]="label"
    [id]="id"
    class="col-span-4 h-2"
    [(ngModel)]="value"
    (ngModelChange)="handleInputChange()"
    (keydown.enter)="handleKeyPress()"
    labelSize="text-xs"
    classes="py-1"
    [inputType]="inputType"
    [numberMin]="0"
    [inputStep]="inputType === 'number' ? inputStep : undefined"
  ></app-input>
  <div [ngClass]="[open ? 'block' : 'hidden']">
    <div class="absolute z-40 left-0 mt-2 w-full">
      <div
        class="py-1 text-sm bg-white rounded shadow-lg border border-gray-300"
      >
        <a
          (click)="handleKeyPress()"
          class="
            block
            py-1
            px-5
            cursor-pointer
            hover:bg-primary-200 hover:text-gray-50
          "
          >{{ tagPlaceholder ?? "actions.add.tag" | translate }} "<span class="font-bold">{{ this.value }}</span>"
        </a>
      </div>
    </div>
  </div>

  <div class="flex flex-wrap gap-2">
    <div *ngFor="let tag of tags; index as idx">
      <div
        class="
          mt-2
          text-primary-400
          bg-primary-50
          flex-1
          text-xs
          p-2
          border-primary-100
          rounded-lg
          hover:bg-primary-100
        "
      >
        <div class="flex gap-2 items-center">
          {{ tag }}
          <fa-icon
            [icon]="faTimes"
            size="sm"
            class="cursor-pointer hover:text-red-400"
            (click)="handleRemoveTag(idx)"
          ></fa-icon>
        </div>
      </div>
    </div>
    <hr *ngIf="tags.length !== 0" class="border border-gray-100 w-full" />
  </div>
</div>
