import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  private currentPage : number = 1;
  private itemsPerPage: 18 | 32 | 48 | 64 = 18
  private totalResults: number = 0;
  private totalPages: number = 0;
  private nextPage: number = 0;

  constructor() { }


  //TODO: workaround - pagination must be reimplemented
  public reset() {
    this.currentPage = 1;
    this.itemsPerPage = 18;
    this.totalResults = 0;
    this.totalPages = 0;
    this.nextPage = 0;
  }

  set setCurrentPage(value: number) {
    this.currentPage = value;
  }

  set setItemsPerPage(value: 18 | 32 | 48 | 64){
    this.itemsPerPage = value;
  }

  set setTotalResults(value: number){
    this.totalResults = value;
  }

  set setTotalPages(value: number){
    this.totalPages = value;
  }

  set setNextPage(value: number){
    this.nextPage=value;
  }

  get getCurrentPage(){
    return this.currentPage;
  }

  get getItemsPerPage(){
    return this.itemsPerPage;
  }

  get getTotalResults(){
    return this.totalResults;
  }

  get getTotalPages(){
    return this.totalPages;
  }

  get getNextPage(){
    return this.nextPage;
  }


}
