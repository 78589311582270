<div
    class="
      focus:outline-none
      block
      w-full
      rounded-md
      bg-gray-100
      border border-transparent
      focus:ring-primary-500 focus:bg-white focus:ring-1
      py-2
      px-3
      
    "
    >
   
    <div *ngIf="checkTool === 'wkt'">
      <p class="font-bold text-secondary-500"> {{ "helper-texts.tooltip.wkt.title" | translate }}</p>
      <ul class="list-disc list-inside">
        <li>{{ "helper-texts.tooltip.wkt.input" | translate }}</li>
        <li>{{ "helper-texts.tooltip.wkt.coordinates" | translate }}</li>
        <li>{{ "helper-texts.tooltip.wkt.format" | translate }}</li>
      </ul>
      </div>
    
    <div *ngIf="checkTool === 'polygon'">
    <p class="font-bold text-secondary-500"> {{ "helper-texts.tooltip.polygon.title" | translate }}</p>
      <ul class="list-disc list-inside">
        <li>{{ "helper-texts.tooltip.polygon.draw" | translate }}</li>
        <li>{{ "helper-texts.tooltip.polygon.close" | translate }}</li>
        <li>{{ "helper-texts.tooltip.polygon.edit" | translate }}</li>
        <li>{{ "helper-texts.tooltip.polygon.delete" | translate }}</li>
        <li>{{ "helper-texts.tooltip.polygon.restart" | translate }}</li>
      </ul>
    </div>

    <div *ngIf="checkTool === 'shapefile'">
      <p class="font-bold text-secondary-500"> {{ "helper-texts.tooltip.shapefile.title" | translate }}</p>
       
          {{ "helper-texts.tooltip.shapefile.description" | translate }}
        
    </div>

    <div *ngIf="checkTool === 'rectangle'">
      <p class="font-bold text-secondary-500"> {{ "helper-texts.tooltip.rectangle.title" | translate }}</p>
       
      <ul class="list-disc list-inside">
        <li>{{ "helper-texts.tooltip.rectangle.draw" | translate }}</li>
        <li>{{ "helper-texts.tooltip.rectangle.edit" | translate }}</li>
        <li>{{ "helper-texts.tooltip.rectangle.restart" | translate }}</li>
      </ul>
    </div>

    
</div>
