import { Routes } from "@angular/router";
import { UnauthorizedLoadGuard } from "@core";
import { NgxPermissionsGuard } from "ngx-permissions";

export const SellerRoutes: Routes = [
    {
        canLoad: [UnauthorizedLoadGuard],
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: 'seller',
                redirectTo: 'home',
            },
        },
        path: 'new-service',
        loadChildren:() => import('../../features/seller/modules').then( m => m.NewServicesModule)
    },
    {
        canLoad: [UnauthorizedLoadGuard],
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: 'seller',
                redirectTo: 'home',
            },
        },
        path: 'services',
        loadChildren: () => import('../../features/seller/modules').then(m => m.MyServicesModule)
    }
]
