import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  jobURL = `${environment.apiUrl}/v1/job`;

  constructor(private httpService: HttpClient) {}

  report(chainJobId: string) {
    return this.httpService
      .patch(`${this.jobURL}/${chainJobId}/report`, null)
      .pipe(take(1));
  }
}
