import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Service } from '@core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  @Input() service?: Service;
  @Input() size = 'md';
  @Input() hasDialogButtons = false;
  @Input() confirmText: string = 'OK';
  @Input() cancelText: string = 'Cancel';
  @Output() confirm = new EventEmitter();
  @Output() cancel = new EventEmitter();

  dialogSize = '';
  convertSize: { [key: string]: string } = {
    sm: 'w-3/12',
    md: 'w-6/12',
    lg: 'w-9/12',
  };
  photo = 'assets/img/backgrounds/myServices.jpg';
  keywords?: string[];
  faCheck = faCheck;
  faTimes = faTimes;

  constructor() {}

  ngOnInit(): void {
    this.dialogSize = this.convertSize[this.size] ?? this.size;
    this.keywords = this.service?.keywords;
  }

  onConfirm() {
    this.confirm.emit();
  }

  onCancel() {
    this.cancel.emit();
  }
}
