import { PaymentMethod, PaymentMethodSlug } from '@core/models';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';

export const paymentMethods: PaymentMethod[] = [
  {
    slug: PaymentMethodSlug.PAYPAL,
    title: 'Paypal',
    icon: faPaypal,
  }
];
