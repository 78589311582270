import { Component, OnInit } from '@angular/core';
import { CMSWmsLayersCard, CmsService, MapService } from '@core';
import { take } from 'rxjs';
import {  Option } from '@core';
import { IconDefinition, faChevronDown, faChevronUp, faXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-wms-layer-info',
  templateUrl: './wms-layer-info.component.html',
  styleUrls: ['./wms-layer-info.component.scss']
})
export class WmsLayerInfoComponent implements OnInit {

  wmsInfo: CMSWmsLayersCard[];
  wmsInfoOptions: Option[];
  isShowingSelect: boolean = true;
  wmsShow: string[] = [];

  faChevronDown: IconDefinition = faChevronDown;
  faMinusfaChevronUp: IconDefinition = faChevronUp;
  faXmark: IconDefinition = faXmark;

  constructor(private cmsService: CmsService, private mapService: MapService) { }

  ngOnInit(): void {
    this.cmsService.getWmsLayerInformation().pipe(take(1)).subscribe({
      next:(res) =>{
         this.wmsInfo = res.data.attributes.wmsLayers;
         this.wmsInfoOptions =  this.wmsInfo.map((option) => ({
          label: option.labelPt,
          value: option.wmsUrl,
        }));
      },error:(erro)=>{

      }
    });
  }

  btnClicked(){
    this.isShowingSelect = !this.isShowingSelect;
  }

  checkWmsItem(wmsSelected:CMSWmsLayersCard[]){
    
    this.wmsShow.forEach((itemClear)=>{
      this.mapService.removeWmsListLayer();
    });
    this.wmsShow = [];
    
    if(wmsSelected.length !== 0){
      wmsSelected.forEach((item)=>{
        this.wmsShow.push(item.wmsUrl);
        this.mapService.addWmsListLayer(item.wmsUrl);
    });
    }
   
  }

}
