import { Injectable } from '@angular/core';
import { Lang, LangId } from '@core/models';
import { TranslateService as NgxTranslateService } from '@ngx-translate/core';
import { enUS, pt } from 'date-fns/locale';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export const langId =
  (localStorage.getItem('__lang_id__') as LangId) ?? Lang.EN;

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private currentLanguageId: LangId;
  private languageChangedSource = new Subject();

  public currentObservableLanguage$ = this.languageChangedSource.pipe(
    map((data) => data)
  );

  constructor(private ngxTranslateService: NgxTranslateService) {
    this.currentLanguageId = langId;

    this.currentLanguage = this.currentLanguageId;
  }

  get currentLanguage() {
    return this.currentLanguageId;
  }

  set currentLanguage(langId: LangId) {
    this.currentLanguageId = langId;
    this.ngxTranslateService.use(langId);
    this.emitLanguageChange(langId);

    localStorage.setItem('__lang_id__', langId);
  }

  emitLanguageChange(newLangId: string) {
    this.languageChangedSource.next(newLangId);
  }

  translate(slug: string) {
    return this.ngxTranslateService.instant(slug);
  }

  get(slug: string | string[]) {
    return this.ngxTranslateService.get(slug);
  }

  get dateLocale() {
    switch (this.currentLanguage) {
      case 'pt':
        return pt;
      case 'en':
        return enUS;
    }
  }
}
