import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Contacts } from '@core/models';
import { httpOptions } from '@core/configs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailChimpService {

  serviceURL = `${environment.apiUrl}/v1`;

  constructor(private http: HttpClient) { }

  
  addEmail(body: Contacts): Observable<Contacts> {
    return this.http.post<Contacts>(`${this.serviceURL}/contact-us`, body, httpOptions);
  }
}
