import {
  animate,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AnalyticsService,
  FilterService,
  PaginationService,
  Service,
  ServicesFilters,
  ServicesService,
} from '@core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faFilter,
  faFilterCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { serializeParams, slugify } from 'shared';

type ServiceLoaderParams = {
  perPage: number;
  page: number;
  filters?: ServicesFilters;
};

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss'],
  animations: [
    trigger('queryAnimation', [
      state(
        'openFilter',
        style({
          'grid-column': 'span 10 / span 10',
        })
      ),
      state(
        'closedFilter',
        style({
          'grid-column': 'span 12 / span 12',
        })
      ),
      transition('closedFilter => openFilter', [
        query(':self', style({ 'grid-column': 'span 10 / span 10' })),
        query(
          ':self',
          animate(
            '300ms ease-out',
            style({ 'grid-column': 'span 10 / span 10' })
          )
        ),
      ]),
      transition('openFilter => closedFilter', [
        query(':self', style({ 'grid-column': 'span 10 / span 10' })),
        query(
          ':self',
          animate(
            '300ms ease-in',
            style({ 'grid-column': 'span 12 / span 12' })
          )
        ),
      ]),
    ]),
    trigger('filterBar', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('100ms ease-out', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('100ms ease-in', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
  ],
})
export class MarketplaceComponent implements OnInit, OnDestroy, AfterViewInit {
  slugify = slugify;

  services$?: Observable<Service[]>;
  isLoading: boolean = true;
  isShowFilters: boolean = true;

  id?: string;

  faFilter: IconDefinition = faFilter;
  faClose: IconDefinition = faFilterCircleXmark;

  paginationAndFilter$ = new BehaviorSubject<ServiceLoaderParams>({
    page: 1,
    perPage: 18,
  });
  componentDestroyed$: Subject<boolean> = new Subject();
  isMobile: boolean = false;
  exp: string = '';

  constructor(
    private servicesService: ServicesService,
    private filterService: FilterService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private paginationService: PaginationService,
    private analyticsService: AnalyticsService
  ) {}

  ngAfterViewInit(): void {
    if (window.innerWidth < 1024) {
      this.isShowFilters = false;
      this.isMobile = true;
    } else {
      this.isShowFilters = true;
      this.isMobile = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.window.innerWidth < 1024) {
      this.isShowFilters = false;
      this.isMobile = true;
    } else {
      this.isShowFilters = true;
      this.isMobile = false;
    }
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((data) => (this.id = data.id));
    this.loadServices();
    this.analyticsService.emitPageViewEvent('marketplace', '/customers/marketplace');
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.paginationService.reset();
  }

  onCategoryCheck() {
    this.paginationAndFilter$.next({
      page: this.paginationService.getCurrentPage,
      perPage: this.paginationService.getItemsPerPage,
      filters: this.filterService.filters,
    });
  }

  onPageChange() {
    this.paginationAndFilter$.next({
      page:
        this.paginationService.getItemsPerPage *
          (this.paginationService.getCurrentPage - 1) +
        1,
      perPage: this.paginationService.getItemsPerPage,
      filters: this.filterService.filters,
    });
  }

  onPerPageChange() {
    this.paginationService.setCurrentPage = 1;
    this.paginationAndFilter$.next({
      page: this.paginationService.getCurrentPage,
      perPage: this.paginationService.getItemsPerPage,
      filters: this.filterService.filters,
    });
  }

  onCardClick(id: string) {
    this.router
      .navigate([`${id}/buy`], {
        relativeTo: this.activatedRoute,
      })
      .finally();
  }

  loadServices() {
    this.paginationAndFilter$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        let params = serializeParams({
          page: data.page,
          perPage: data.perPage,
          ...data.filters,
        });

        this.services$ = this.servicesService.getCatalogueServices(params).pipe(
          map((data) => {
            this.paginationService.setTotalResults = data.totalResults;
            return data.services;
          })
        );
      });
  }

  get pageTotalResults() {
    return this.paginationService.getTotalResults;
  }
}
