import * as L from 'leaflet';
import { convertToWK } from 'wkt-parser-helper';
import { format as datefnsFormat } from 'date-fns'

function toLatLngs(layer: L.Polygon | L.Polyline | L.Marker) {
  const geoJSON = layer.toGeoJSON();
  let latLngs = geoJSON.geometry.coordinates;
  let points = geoJSON.geometry.coordinates.length;

  if (layer instanceof L.Polygon) {
    const polyLatLngs: any = latLngs[0];
    polyLatLngs.splice(polyLatLngs.length - 1, 1);
    const polyPoints = polyLatLngs.length;
    return { latLngs: polyLatLngs, points: polyPoints };
  } else if (layer instanceof L.Polyline) return { latLngs, points };
  else return { latLngs: [latLngs], points };
}

function toDegreesMinutesAndSeconds(latLng: number) {
  const absolute = Math.abs(latLng);

  const degrees = Math.floor(absolute);
  const subtract = (absolute - degrees) * 60;
  const minutes = parseFloat(subtract.toFixed(3));
  const floorMinutes = Math.floor(subtract);
  const seconds = parseFloat(((subtract - floorMinutes) * 60).toFixed(2));

  const raw = () => ({ degrees, minutes, seconds });

  return { parsed: `${degrees}° ${minutes}'`, raw };
}

function toDms(lat: number, lng: number) {
  const latitude = toDegreesMinutesAndSeconds(lat).parsed;
  const latitudeDirection = lat >= 0 ? 'N' : 'S';

  const longitude = toDegreesMinutesAndSeconds(lng).parsed;
  const longitudeDirection = lng >= 0 ? 'E' : 'W';

  const rawLat = toDegreesMinutesAndSeconds(lat).raw();
  const rawLng = toDegreesMinutesAndSeconds(lng).raw();

  return {
    lat: `${latitude} ${latitudeDirection}`,
    lng: `${longitude} ${longitudeDirection}`,
    rawLat: {
      ...rawLat,
      direction: latitudeDirection,
    },
    rawLng: {
      ...rawLng,
      direction: longitudeDirection,
    },
  };
}

function toWkt(
  layer:
    | L.Polygon
    | L.Polygon[]
    | L.Polyline
    | L.Polyline[]
    | L.Marker
    | L.Marker[]
    | L.Layer[]
) {
  if (layer instanceof Array) {
    const geoLayers = L.geoJSON();
    layer.forEach((layer: L.Polygon | L.Polyline | L.Marker | L.Layer) =>
      geoLayers.addLayer(layer)
    );
    const geoJSON = geoLayers.toGeoJSON();

    return convertToWK(geoJSON);
  }

  const geoJSON = layer.toGeoJSON();
  return convertToWK(geoJSON.geometry);
}

function slugify(...args: (string | number)[]): string {
  const value = args.join(' ');

  return value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '-');
}

function dateFormat(dateParam: Date | string, format: string): string {
  let date = new Date(dateParam);
  return datefnsFormat(date, format);
}

function convertSquareKMArea(area: number, targetUnit: string | undefined): number {
  if (targetUnit === 'SQM') {
    return area*1000000;
  } else if (targetUnit === 'HA') {
    return area*100;
  } else {
    return area;
  }
}


export { toDms, toWkt, toLatLngs, slugify, dateFormat, convertSquareKMArea };
