<div class="min-h-[80vh]">
  <app-header
    [label]="'pages-headers.my-orders' | translate"
    [overLabel]="'pages-headers.pages-names.my-orders' | translate"
  ></app-header>
  <div class="flex flex-row gap-2 px-8">
    <span class="my-auto text-lg text-secondary-400"><b>{{ 'labels.subscriptions-only' | translate }}</b></span>
    <app-icon-button 
      (btnClick)="filterSubscriptionOrders()"
      [icon]="subscriptionsFiltered ? faToggleOn : faToggleOff"
      [iconSize]="'xl'"
    ></app-icon-button>
  </div>
  <ng-container *ngIf="!isLoading">
    <ng-container *ngIf="!noOrders; else noData">
      <div class="flex flex-col flex-1 bg-gray-50">
        <div class="container">
          <div class="p-4 px-6 w-screen flex flex-col gap-2">
            <app-pagination
              *ngIf="pageTotalResults > 0"
              classes="px-4"
              (currentPageChange)="onPageChange()"
              (perPageChange)="onPerPageChange()"
            ></app-pagination>
            <div class="flex flex-row justify-end px-4 gap-4" *ngIf="paymentPendingOrders.length > 0">
              <span class="my-auto text-primary-500">{{ 'bulk-payment.alert' | translate }}.</span>
              <app-button
                [label]="'actions.pay-all' | translate"
                (btnClick)="onPayAllClick()"
                [isDisabled]="isLoadingBulkPayment || isProcessingPayment"
                [leftIcon]="isLoadingBulkPayment ? faSpinner : undefined"
                iconClasses="animate-spin"
                [isSecondary]="true"
              ></app-button>
            </div>
          </div>
          <ng-container *ngFor="let order of orders">
            <app-order-item [order]="order" 
              [disablePaymentButton]="isLoadingBulkPayment || isProcessingPayment"
            ></app-order-item>
          </ng-container>
          <div class="p-4 px-6 w-screen">
            <app-pagination
              *ngIf="pageTotalResults > 0"
              classes="px-4"
              (currentPageChange)="onPageChange()"
              (perPageChange)="onPerPageChange()"
            ></app-pagination>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noData>
      <ng-container *ngIf="noOrders && !hasError; else errorOnFetching">
        <div
          class="flex flex-col flex-1 text-gray-400 bg-gray-50 justify-center items-center"
        >
          <fa-icon [icon]="noOrdersIcon" size="5x"></fa-icon>
          <h1 class="mt-4">
            {{ "not-found.orders.empty" | translate }}
            <a
              class="animate-pulse ml-0.5 hover:text-primary-500 duration-300"
              routerLink="/customers/marketplace"
              >{{ "actions.go.to-services-page-purchase" | translate }}</a
            >
          </h1>
        </div>
      </ng-container>
      <ng-template #errorOnFetching>
        <div
          class="flex flex-col flex-1 text-red-400 bg-gray-50 justify-center items-center"
        >
          <fa-icon [icon]="hasErrorIcon" size="5x"></fa-icon>
          <h1 class="mt-4">
            {{ "errors.my-orders-loading-page" | translate }}
          </h1>
        </div>
      </ng-template>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="isLoading">
    <div class = "z-50 relative col-span-12 ">
      <div
        class = "
          grid
          grid-cols-4
          col-span-4
          place-content-center
          h-screen
          z-50
          shadow
          bg-gray-50
          gap-4
          absolute
          w-full
          p-4
      "
      >
        <p class = "col-span-4 place-self-center text-gray-500">{{'actions.loading.orders'|translate}}</p>
        <div class = "col-span-2 p-2 bg-gray-300 rounded"></div>
        <div class = "col-span-4 p-2 bg-gray-300 rounded"></div>
        <div class = "flex justify-between col-span-4 gap-4"></div>
      </div>
    </div>
  </ng-container>
</div>

<app-dialog
  size="md"
  *ngIf="isBulkPaymentReady"
  [hasDialogButtons]="true"
  [confirmText]="'actions.confirm' | translate"
  [cancelText]="'actions.cancel' | translate"
  (cancel)="onBulkPaymentClose()"
  (confirm)="confirmBulkPayment()"
>
  <div class="w-full px-2 pb-4 text-center">
    <span class="text-xl font-bold">{{ 'bulk-payment.dialog.title' | translate }}</span>
  </div>
  <div class="grid grid-cols-5 font-bold" *ngIf="paymentPendingOrders.length > 0">
    <span class="col-span-1">{{ 'bulk-payment.dialog.orderId' | translate }}</span>
    <span class="col-span-3">{{ 'bulk-payment.dialog.service' | translate }}</span>
    <span class="col-span-1 text-right">{{ 'bulk-payment.dialog.price' | translate }}(EUR)</span>
  </div>
  <div class="max-h-40 overflow-y-auto">
    <div class="grid grid-cols-5" *ngFor="let order of paymentPendingOrders">
      <span class="col-span-1">{{ order.id }}</span>
      <span class="col-span-3">{{ serviceOrderMap[order.id].title }}</span>
      <span class="col-span-1 text-right">{{ orderPriceMap[order.id] }}</span>
    </div>
  </div>
  <div class="flex flex-row justify-end gap-4 mt-4 border-t-2 py-2">
    <span><b>{{ 'bulk-payment.dialog.total' | translate }}:</b></span>
    <span>{{ bulkPriceTotal | currency: "EUR " : "code" }}</span>
  </div>
</app-dialog>

<app-dialog
  *ngIf="isBulkPaymentConfirmed"
  (cancel)="onPaypalDialogCancel()"
  size="sm"
>
<div class="flex flex-col gap-3">
  <h1 class="text-secondary-500">
    {{ "helper-texts.preferred-payment-method" | translate }}
  </h1>
  <div class="flex flex-wrap flex-shrink-0 gap-4">
    <p
      *ngIf="!isBulkPaymentConfirmed; else priceLoaded"
      class="place-self-center text-gray-500"
    >
      {{ "actions.loading.page-content" | translate }}
    </p>
    <ng-template #priceLoaded>
      <app-button
        *ngFor="let payment of paymentMethods"
        (btnClick)="onPaymentClick(payment.slug)"
        [isWhite]="true"
        [label]="payment.title"
        [title]="payment.title"
        [leftIcon]="payment.icon"
        [isSecondary]="selectedPayment === payment.slug"
      ></app-button>
    </ng-template>
  </div>

  <span *ngIf="isBulkPaymentConfirmed && selectedPayment === 'PAYPAL'">
    <div>
      <paypal-checkout 
      [price]="bulkPriceTotal"
      (onClientAuthorization)="onPaypalDialogCancel()"
      (onApprove)="onPaymentApproved($event)"
      ></paypal-checkout>
    </div>
  </span>
</div>
</app-dialog>
