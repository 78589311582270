<div
  class="dialog fixed inset-0 overflow-y-auto z-50"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      aria-hidden="true"
    ></div>

    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true"
      >&#8203;</span
    >
    <div
      (outsideClick)="onCancel()"
      [ngClass]="dialogSize"
      class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
    >
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <ng-content></ng-content>
        <div class="flex flex-row justify-center pb-2 gap-4" *ngIf="hasDialogButtons">
          <app-button
              [label]="confirmText"
              (click)="onConfirm()"
          ></app-button>
          <app-button
              [label]="cancelText"
              (click)="onCancel()"
          ></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
