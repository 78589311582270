import {Validators} from '@angular/forms';

export const serviceDetailsFormConfig = {
  title: [null, Validators.required],
  description: [null, Validators.required],
  shortDescription: [null],
  serviceType: [null, Validators.required],
  serviceURI: [null],
  serviceSubType: [null, Validators.required],
  serviceCategoryId: [null, Validators.required],
  keywords: [[], Validators.required],
  publishers: [[], Validators.required],
  isSubscription: [false, Validators.required],
  isAutoExecution: [false, Validators.required],
  documentationURI: [null],
  eulaUri: [null],
  priceModel: [null,Validators.required ],
  legalConstraints: [null, Validators.required],
  language: ["en", Validators.required],
  purpose: [null],
  versionNumber: [null, Validators.required],
  serviceStart: [null, Validators.required],
  serviceStartDelta: [null, Validators.required],
  serviceStop: [null, Validators.required],
  serviceStopDelta: [null, Validators.required],
  serviceCoordinates: [null, Validators.required],
  sampleURIs: [[]],
  measureUnits: [null],
  unitPrice: [null],
  serviceGroups: [[]],
  timeInterval: [null],
  serviceInstructions: [null],
  supportURI: [null],
  thumbnailURI: [null],
  organizationURI: [null],
  organizationLogoURI: [null],
  serviceOrderLimits: [null],
  associatedServices: [null],
  isTryAndBuy: [false, Validators.required],
  tryAndBuyParameters: [null],
  customRequestPrice: [null],
  orderFee: [null, Validators.required],
  aoiScale: [null],
  toiScale: [null],
  allowedGeometry: [null],
  timeStep: [[], Validators.required],
  executionTimestamp: [null, Validators.required]
};

export const associatedServiceConfig = {
  name: [null],
  description: [null],
  uri: [null]
}

export const tryAndBuyParameterConfig = {
  aoi: [null, Validators.required],
  toiStartDate: [null, Validators.required],
  toiEndDate: [null, Validators.required]
}

export const measureUnitConfig = {
  area: [null],
  time: [null]
}

export const unitPriceConfig = {
  time: [null],
  area: [null],
  timeArea: [null]
}

export const resolutionConfig = {
  spatial: [null],
  temporal: [null]
}
