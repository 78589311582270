import { Component, Input, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  @Input() label: string = "Loading";

  options: AnimationOptions = {
    path: '/assets/lottie/world.json'
  };

  constructor() { }

  ngOnInit(): void {
  }

}
