import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  DynamicInputsControlService, INewOrder,
  InputService, LegalConstraints, Order, PaymentService, PriceInfo, Service, ServicePeriod, Subtype, ToiMeasureUnits, Type
} from '@core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { OidcSecurityService } from 'angular-auth-oidc-client';

import { Observable, Subject, take } from 'rxjs';
import { DynamicInputBase, hasUndefinedProperty } from 'shared';

import { environment } from '../../../../../../../environments';

@Component({
  selector: 'app-make-order[geoRecordIdentifier]',
  templateUrl: './make-order.component.html',
  styleUrls: ['./make-order.component.scss'],
  providers: [InputService],
})
export class MakeOrderComponent implements OnInit, OnDestroy {
  isOrderReady: boolean = false;
  orderData?: INewOrder;
  isPriceLoading: boolean  = false;

  faSpinner = faSpinner

  @Input() servicePeriod?: ServicePeriod;

  @Input() isOrderLoading: boolean = false;
  @Input() serviceFootprint?: string;
  @Input() geoRecordIdentifier!: string;
  @Input() geoRecordType?: Type;
  @Input() geoRecordSubType?: Subtype;
  @Input() service!: Service;
  @Input() ordersDisabled: boolean = false;
  @Output() closeModal = new EventEmitter();
  @Output() data = new EventEmitter<INewOrder>();

  inputs: DynamicInputBase<string>[] = [];
  inputs$?: Observable<DynamicInputBase<string>[]>;
  componentDestroyed$: Subject<boolean> = new Subject();
  isMobile: boolean = false;
  payment?: PriceInfo;
  env = environment;

  form: UntypedFormGroup = this.dynamicInputsControlService.toFormGroup(this.inputs);

  constructor(
    private inputService: InputService,
    private activatedRoute: ActivatedRoute,
    private paymentService: PaymentService,
    private dynamicInputsControlService: DynamicInputsControlService,
    private oidc: OidcSecurityService
  ) {
  }

  get isLoading() {
    return this.inputService.isLoading;
  }

  get isServicePaid() {
    return this.service.legalConstraints === LegalConstraints.COMMERCIAL
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnInit(): void {
   
    this.oidc.isAuthenticated().pipe(take(1)).subscribe({
      next: (authenticated)=>{

        this.inputs$ = this.inputService.getInputs(
          this.activatedRoute.snapshot.paramMap.get('id') as string,
          this.geoRecordType,
          this.servicePeriod,
          this.serviceFootprint,
          authenticated
        );

      },error: (error) => {}
    });

    if (!this.geoRecordIdentifier)
      throw new TypeError('GeoRecordIdentifier is required');

    
    this.resetForm();
    

  }

  emitData(data: INewOrder) {
    data.serviceId = this.geoRecordIdentifier;
    this.data.emit(data);
  }

  onFormValuesChange(order: Order) {
    if (this.isServicePaid && !hasUndefinedProperty(order)) {
      this.isPriceLoading = true;
      this.oidc.getUserData().pipe(take(1))
      .subscribe((userData) => {
        this.paymentService
        .calculatePrice(
          order, this.service,
          userData.originCountry ?? 'PT',
          userData.vatPercentage ? parseFloat(userData.vatPercentage) : undefined
        )
        .subscribe({ next: (payment) => {
          this.payment = payment;

          this.isPriceLoading = false;
        }, error: (error) => {
          console.error(error);
          this.isPriceLoading = false;
        }, complete: () => {}});
      });
    } else {
      this.payment = undefined;
    }
  }

  resetForm(){
    
    if(window.innerWidth > 1024 && this.isMobile == false){
      this.isMobile = true;
      this.payment = undefined;
    }

    if(window.innerWidth < 1024 && this.isMobile == true){
      this.isMobile = false;
      this.payment = undefined;
    }
    
  }

  onRequest() {
    window.open(this.env.externalLinks.requestAreaUrl, '_blank');
  }
  
  get userData() {
    return this.oidc.getUserData();
  }

 
}

