<div class="w-72 pt-1">
    <div (mouseleave)="showDropDown = false">
        <button class="bg-white text-black w-full rounded-lg h-8 flex place-content-between p-1 px-4" (click)="showDropDown=!showDropDown">
            <span *ngIf="checkedList.length <= 0">{{'wmsInfo.select' | translate}}</span>
            <span *ngIf="checkedList.length === 1">{{checkedList.length}} {{'wmsInfo.item' | translate}}</span>
            <span *ngIf="checkedList.length > 1">{{checkedList.length }} {{ 'wmsInfo.items' | translate}}</span>
            <fa-icon [icon]="faChevronDown"></fa-icon>
            
        </button>
        <div class="w-full bg-white text-black p-2 border border-primary-500 rounded-lg mt-1" *ngIf="showDropDown">
            <label *ngFor="let item of list" class="block break-words">
                <input type="checkbox" [(ngModel)]="item.checked" 
                    (change)="getSelectedValue(item.checked,item)" 
                    class="rounded mr-2 text-secondary-500 focus:ring-secondary-500"/>
                <span>{{ formattedLabel(isEnglish ? item.labelEn : item.labelPt) }}</span>
            </label>
        </div>
    </div>
</div>
