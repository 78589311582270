import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from '../../../environments/environment';
@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: environment.authority,
        redirectUrl: `${window.location.origin}/callback`,
        postLogoutRedirectUri: `${window.location.origin}/sign-out`,
        clientId: environment.clientId,
        scope: environment.scope,
        responseType: 'id_token token',
        // useRefreshToken: true,
        // silentRenew: true,
        // startCheckSession:true,
        // renewTimeBeforeTokenExpiresInSeconds: 10,
        // silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
        // logLevel: environment.production ? LogLevel.None : LogLevel.Debug,
      },
    }),
  ],
  exports: [AuthModule],
  providers: [],
})
export class AuthConfigModule {}
