import { Component, OnInit } from '@angular/core';
import { LangId, LOCALE_ID, TranslateService, AnalyticsService } from '@core';
import { PopupService } from '@core/services/popup/popup.service';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  langId: LangId = LOCALE_ID;
  scrollTop = 0;
  isHomePage: boolean = false

  faCheck = faCheck

  get orderId() {
    return this.popupService.orderId;
  }

  constructor(private translateService: TranslateService,
              private popupService: PopupService,
              private cookieConsentService: NgcCookieConsentService,
              private analyticsService: AnalyticsService,) {}

  ngOnInit(): void {
    this.updateCookieConsent();
    this.analyticsService.emitPageViewEvent('main_page', '/home');
  }

  setLang(langId: LangId) {
    this.translateService.currentLanguage = langId;
    this.updateCookieConsent();
  }

  onContainerScroll($event: Event) {
    const element = $event.target as HTMLElement;
    this.scrollTop = element.scrollTop;
  }

  updateCookieConsent() {
    this.translateService
      .get(['cookie.message','cookie.allow', 'cookie.policy'])
      .subscribe(data => {

        this.cookieConsentService.getConfig().content = this.cookieConsentService.getConfig().content || {} ;

        const { content } = this.cookieConsentService.getConfig();

        // Override default messages with the translated ones
        if (content) {
          content.message = data['cookie.message'];
          content.allow = data['cookie.allow'];
          content.policy = data['cookie.policy'];
        }


        this.cookieConsentService.destroy(); // remove previous cookie bar
        this.cookieConsentService.init(this.cookieConsentService.getConfig()); // update config with translated messages
      });
  }
}
