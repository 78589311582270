<label
  id="label-left"
  for="toggle"
  class="text-lg text-gray-700"
  [ngClass]="isSelectedClass(false)"
  ><fa-icon *ngIf="leftIcon" class="px-2" [icon]="leftIcon"></fa-icon>{{ leftLabel }}</label
>
<div
  [id]="id"
  class="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in"
>
  <input
    type="checkbox"
    name="toggle"
    id="toggle"
    class="toggle-checkbox absolute block m-1 w-4 h-4 rounded-md bg-white appearance-none cursor-pointer"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)"
  />
  <label
    for="toggle"
    class="toggle-label bg-secondary-200 block overflow-hidden h-6 rounded-lg cursor-pointer"
  ></label>
</div>
<label
  id="label-right"
  for="toggle"
  class="text-lg text-gray-700"
  [ngClass]="isSelectedClass(true)"
  ><fa-icon class="px-2" *ngIf="rightIcon" [icon]="rightIcon"></fa-icon>{{ rightLabel }}</label
>
