import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() iconClasses?: NgClass["ngClass"];
  @Input() classes?: string;
  @Input() label: string = 'Button label';
  @Input() leftIcon?: IconDefinition;
  @Input() rightIcon?: IconDefinition;
  @Input() isSecondary?: boolean = false;
  @Input() isTransparent?: boolean = false;
  @Input() isOutline?: boolean = false;
  @Input() isDark?:boolean=false;
  @Input() isSuccess?: boolean = false;
  @Input() isDangerous?: boolean = false;
  @Input() isDisabled:boolean = false;
  @Input() isWhite:boolean = false;
  @Input() isFull:boolean = false;
  @Input() type: string = 'button';
  @Output() btnClick = new EventEmitter();
  @Input() isUppercase: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.btnClick.emit();
  }

  isDisabledClass() {
    return this.isDisabled ? 'opacity-50 hover:cursor-not-allowed' : "hover:cursor-pointer ease-in-out motion-safe:hover:scale-95 motion-safe:active:scale-90 active:shadow-inner"
  }
}
