import {NgModule} from '@angular/core';
import {BuyComponent, MarketplaceComponent, ShopIndex} from './pages';
import {SharedModule} from 'shared';
import {ShopRoutingModule} from './shop-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  MakeOrderComponent,
  ServiceDetailsComponent,
  ServiceProductsSpecificationComponent,
  ServiceInstructionsComponent,
  ServicePriceModelComponent,
  EvaluationsComponent
} from './components';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { DomainAreaFilterComponent } from './components/domain-area-filter/domain-area-filter.component';

@NgModule({ 
  declarations: [
    ShopIndex,
    BuyComponent,
    ServiceDetailsComponent,
    ServiceProductsSpecificationComponent,
    ServiceInstructionsComponent,
    MakeOrderComponent,
    ServicePriceModelComponent,
    MarketplaceComponent,
    EvaluationsComponent,
    DomainAreaFilterComponent
  ],
  imports: [
    SharedModule,
    ShopRoutingModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule
  ],
})
export class ShopModule {
}
