import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class NavigationService {
  private history: string[] = [];
  public navigate$ = new Subject<boolean>();
  

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back(alternativeURL?: string): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else if (alternativeURL) {
      this.router.navigateByUrl(alternativeURL).finally()
    }else{
      this.router.navigateByUrl('/').finally();
    }
    
  }

  onNavigate(){
    this.navigate$.next(true)
  }
}
