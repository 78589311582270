import { Injectable } from '@angular/core';
import { snackbars } from '@core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  public isSnackbarActive$ = new BehaviorSubject(false);

  public title: string = 'Success';
  public message: string = 'it worked!';
  public url: string = environment.externalLinks.requestAreaUrl;
  public flag: boolean = false;

  private snackbar = 'success';

  public snackbarConfig = {
    deactivateAfter: 2000,
    snackbar: snackbars[this.snackbar],
    desactiveBool:false
  };
  constructor() {}

  private setSnackbarOptions(type: string, title: string, message: string) {
    this.flag = false;
    this.setSnackbarConfig(type);
    const during = (time: number) => {
      this.setSnackbar(title, message, time);

      const show = () => {
        this.setSnackbarActive();
      };
      return { show };
    };

    return { during };
  }

  private setSnackbarOptionsLink(type: string, title: string, message: string, url: boolean) {
    this.setSnackbarConfig(type);
    const during = (action: boolean) => {
      this.setSnackbarLink(title, message, url, action);

      const show = () => {
        this.setSnackbarActiveLink();
      };
      return { show };

      
    };

    return { during };
  }

  private setSnackbarConfig(snackbar: string) {
    this.snackbarConfig.snackbar = snackbars[snackbar];
  }

  private setSnackbarLink(title: string, message: string, url:boolean, action: boolean) {
    this.title = title;
    this.message = message;
    this.flag = url;
    this.snackbarConfig.desactiveBool = action;
  }

  private setSnackbar(title: string, message: string, time: number) {
    this.title = title;
    this.message = message;
    this.snackbarConfig.deactivateAfter = time;
  }

  private setSnackbarActive() {
    this.isSnackbarActive$.next(true);
    setTimeout(() => {
      this.isSnackbarActive$.next(false);
    }, this.snackbarConfig.deactivateAfter);
  }

  private setSnackbarActiveLink(){
    this.isSnackbarActive$.next(true);
  }

  public success = (title: string, message: string) =>
    this.setSnackbarOptions('success', title, message);

  public info = (title: string, message: string) =>
    this.setSnackbarOptions('info', title, message);

  public warning = (title: string, message: string) =>
    this.setSnackbarOptions('warning', title, message);

  public danger = (title: string, message: string) =>
    this.setSnackbarOptions('danger', title, message);

  public link = (title: string, message: string, url:boolean) =>
    this.setSnackbarOptionsLink('info', title, message,url);
}
