import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { addDays, format, subDays } from "date-fns";
import {
  IAngularMyDpOptions,
  IMyDateModel,
  IMyDateRangeModel,
  IMyDefaultMonth,
  IMySingleDateModel,
} from 'angular-mydatepicker';
import { Subject, takeUntil } from 'rxjs';
import { DateCaldendarService } from '@core/services/date-calendar/date-caldendar.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnDestroy {
  setRangeDate = format;
  @Input() id: string = 'datePickerID';
  @Input() label: string = 'datePickerID';
  @Input() name: string = 'datePickerName';
  @Input() helperText: string;
  @Input() minDate!: Date | undefined;
  @Input() maxDate!: Date | undefined;
  @Input() minRangeDate: number;
  @Input() isDateRange?: boolean;
  @Input() useMinDateAsDefault?: boolean;
  @Output() selectedDate = new EventEmitter<
    IMySingleDateModel | IMyDateRangeModel
  >();
  myDpOptions: IAngularMyDpOptions = {
    dateRange: true,
    dateFormat: 'dd/mm/yyyy',
    defaultView: 2
  };
  defaultMonth: IMyDefaultMonth;
  model?: IMyDateModel;

  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(public dateService: DateCaldendarService) {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
 
  ngOnInit(): void {

    if (this.minDate) {
      this.minDate = new Date(this.minDate);
      this.minDate = subDays(this.minDate, 1);
      this.myDpOptions.disableUntil = {
        year: this.minDate.getFullYear(),
        month: this.minDate.getMonth() + 1,
        day: this.minDate.getDate(),
      };
      this.myDpOptions.dateRange = this.isDateRange;
      if (this.useMinDateAsDefault) {
        this.defaultMonth = {
          defMonth: `${addDays(this.minDate, 1).getFullYear()}/${addDays(this.minDate, 1).getMonth() + 1}`,
          overrideSelection: false
        }
      }
    }
    if (this.maxDate) {
      this.maxDate = new Date(this.maxDate);
      this.maxDate = addDays(this.maxDate, 1);
      this.myDpOptions.disableSince = {
        year: this.maxDate.getFullYear(),
        month: this.maxDate.getMonth() + 1,
        day: this.maxDate.getDate(),
      };
      if(this.defaultMonth === undefined){
        this.defaultMonth = {
          defMonth: `${subDays(this.maxDate, 1).getFullYear()}/${subDays(this.maxDate, 1).getMonth() + 1}`,
          overrideSelection: false
        }
      }
    
    }

    this.dateService.defaultDate$.pipe(takeUntil(this.componentDestroyed$)).subscribe((newDate)=>{
        
      const year = newDate.singleDate?.date?.year;
      const month = newDate.singleDate?.date?.month;
       
      this.defaultMonth = {
        defMonth: `${year}/${month}`,
        overrideSelection: false
      }
    });
 
  }

  onDateChanged(event: IMyDateModel): void {
    if (event.isRange) {
      this.selectedDate.emit(event.dateRange);
    } else {    
      this.dateService.defaultDate$.next(event);
      this.selectedDate.emit(event.singleDate);
    }
  }
}
