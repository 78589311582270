import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicInputBase } from '../../../shared';

@Injectable({
  providedIn: 'root',
})
export class DynamicInputsControlService {
  constructor() {}

  toFormGroup(inputs: DynamicInputBase<any>[] | null) {
    const group: any = {};
    if (inputs)
      inputs.forEach((input) => {
        group[input.key] = input.required
          ? new UntypedFormControl(input.value || '', Validators.required)
          : new UntypedFormControl(input.value || '');
      });
    return new UntypedFormGroup(group);
  }
}
