import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MarketplaceComponent} from './pages';


const routes: Routes = [
  {path: '', component: MarketplaceComponent,},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShopRoutingModule {
}
