import { Injectable } from '@angular/core';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {take} from "rxjs/operators";
import {NgxPermissionsService} from "ngx-permissions";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
      private oidcSecurityService: OidcSecurityService,
      private permissionsService: NgxPermissionsService,
  ) { }

  signIn(): void {
    this.oidcSecurityService.authorize();
  }

  signOut() {
    this.permissionsService.flushPermissions();
    return this.oidcSecurityService.logoffAndRevokeTokens()
  }

  get isLoggedIn() {
    return this.oidcSecurityService.isAuthenticated();
  }

}
