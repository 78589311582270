import {Routes} from "@angular/router";
import * as path from "path";

export const HomeRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('../../features/home/modules/home-page/home-page.module').then(m => m.HomePageModule)
    },
    {
        path:'about',
        loadChildren: () => import('../../features/home/modules/about-page/about-page.module').then(m => m.AboutPageModule)
    },
    {
        path: 'resources',
        loadChildren: () => import('../../features/home/modules/resources-page/resources-page.module').then(m => m.ResourcesPageModule)
    },
    {
        path: 'contacts',
        loadChildren: () => import('../../features/home/modules/contacts/contacts.module').then(m => m.ContactsModule)
    },
    // {
    //     path: 'support',
    //     loadChildren: () => import('../../features/home/modules/support-page/support-page.module').then(m => m.SupportPageModule)
    // },
]
