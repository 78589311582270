import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { getPropsInObject } from 'shared';

const DEFAULT_MESSAGE = 'Something bad happened; please try again later.'

export const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',

  }),
};

export function handleError(error: HttpErrorResponse) {
  const message = getPropsInObject(error, "message")
  const status = getPropsInObject(error, "status")

  if (status === 0) console.error('An error occurred:', error.error);
  else
    console.error(
      `Backend returned code ${status}, with message: ${ message }`,
    );
  throw new Error(message ?? DEFAULT_MESSAGE);
}
