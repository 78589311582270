import { Component, Input, OnInit,Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { addDays, format, subDays, subMonths, subWeeks, subYears } from 'date-fns';
import { TimeOfInterestInput } from '../classes';
import { IMySingleDateModel, IN } from 'angular-mydatepicker';
import { TimeArea } from '@core';



@Component({
  selector: 'app-dynamic-input-toi',
  templateUrl: './dynamic-input-toi.component.html',
  styleUrls: ['./dynamic-input-toi.component.scss'],
})
export class DynamicInputTOIComponent implements OnInit {
  @Input() input!: TimeOfInterestInput;
  @Input() form!: UntypedFormGroup;
  @Input() isVisible?: boolean = true;
  @Input() flagMax?: boolean = false;
  @Input() flagMin?: boolean = false;
  @Input() newMaxDate?: Date;
  @Input() newMinDate?: Date;
  @Input() timeLimit?: TimeArea;
  @Input() useMinDateAsDefaultMonth?: boolean;
  @Input() isSubscription?: boolean
  @Output() userNewData = new EventEmitter<Date>();
  @Output() dateReset = new EventEmitter();
  @Output() dateSelected = new EventEmitter();


  hasError: boolean = false;
  newServiceEndDate: Date;

  constructor() {
  }

  ngOnInit(): void {
   
    this.form.valueChanges.subscribe(() => {
      if (this.form.hasError('invalidRange')) {
        this.hasError = true;
        return;
      }
      this.hasError = false
    })
  }

  onDateSelected(date: IMySingleDateModel) {
    const dateFormat = "yyyy-MM-dd'T'HH:mm:ss";
    if (date.jsDate) {
      if (this.input.key === 'toiEndDate') {
        date.jsDate.setHours(23, 59, 59);
      }
      this.form.get(this.input.key)?.setValue(format(date.jsDate, dateFormat));
      this.userNewData.emit(date.jsDate)
    }
    
    this.dateSelected.emit(date.jsDate)
    
  }

  get maxDate() {
    if (this.isSubscription) {
      return this.flagMax && this.newMaxDate ? this.newMaxDate : undefined;
    } else {
      if(this.timeLimit?.min && this.input.serviceEndDate){
        if(this.input.key === 'toiStartDate'){
         this.newServiceEnd();
         return this.flagMax && this.newMaxDate ? this.newMaxDate : this.newServiceEndDate;
        }
       }
       return this.flagMax && this.newMaxDate ? this.newMaxDate : this.input.serviceEndDate;
    }
  }

  get minDate() {
    if (this.isSubscription) {
      return this.flagMin && this.newMinDate ? this.newMinDate : new Date();
    } else {
      return this.flagMin && this.newMinDate ? this.newMinDate : this.input.serviceStartDate;
    }
  }


  newServiceEnd(){
    if(this.input.serviceEndDate){
      switch (this.timeLimit?.unit) {
        case 'DAY':
          this.newServiceEndDate = subDays(new Date(this.input.serviceEndDate), this.timeLimit.min);
          break;
        case 'WEEK':
          this.newServiceEndDate = subWeeks(new Date(this.input.serviceEndDate), this.timeLimit.min);
          break;
        case 'MONTH':
          this.newServiceEndDate = subMonths(new Date(this.input.serviceEndDate), this.timeLimit.min);
          
          break;
        case 'YEAR':
          this.newServiceEndDate = subYears(new Date(this.input.serviceEndDate), this.timeLimit.min);
          break;
        default:
          break;
      }
    }
  }

}
