import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconDefinition, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-checkbox-select',
  templateUrl: './checkbox-select.component.html',
  styleUrls: ['./checkbox-select.component.scss']
})
export class CheckboxSelectComponent implements OnInit {

  
  @Input() list:any[];  
  @Output() shareCheckedList = new EventEmitter();

  showDropDown:boolean;
  faChevronDown: IconDefinition = faChevronDown;
  isEnglish: boolean = this.translateService.currentLang === 'en';
  checkedList : any[];

  LABEL_MAX_LENGTH = 30;

  constructor( private translateService: TranslateService) {
    this.checkedList = [];
  }

  ngOnInit(): void {
    
  }


  getSelectedValue(status: boolean , value: any){
     
    if (status) {

      this.checkedList.push(value);  

    } else {

      let index = this.checkedList.indexOf(value);
      this.checkedList.splice(index,1);

    }

    this.shareCheckedlist();
      
  }

  shareCheckedlist(){
    this.shareCheckedList.emit(this.checkedList);
  }
 
  formattedLabel(label: string): string {
    if (label.length > this.LABEL_MAX_LENGTH) {
      return [label.slice(0, this.LABEL_MAX_LENGTH), '...'].join('');
    } else {
      return label;
    }
  }

}
