<div *ngIf="input.key === 'toiEndDate'" class="w-full flex ">
    <app-label-secondary-color 
      [label]="input.label"
      [helperText]="input.helperText" 
    ></app-label-secondary-color>
    <app-label-secondary-color
      [label]="'(' + ('main-page.orders.measureUnits.range.select-period' | translate) + ')'"
      [isRequired]="true"
    ></app-label-secondary-color>
  </div>
  <div class="mt-2 flex flex-row">
  <input 
    id="inputNumber"
    type="number" 
    class="
    focus:outline-none
    block
    w-6/12
    rounded-md
    bg-gray-100
    border border-transparent
    focus:ring-primary-500 focus:bg-white focus:ring-1
    py-2
    px-3
    text-center"
    (change)="inputChange($event)"
    [value]="value"
    (keypress)="ignoreFloat($event)"
    [min]="newMinLimitDate"
    [max]="newMaxLimitDate"
  >
  <div *ngIf="endDate !== undefined && input.key === 'toiEndDate' && this.dateSelected" class="w-full ml-4">
    <div class="
    focus:outline-none
    block
    rounded-md
    border border-transparent
    focus:ring-primary-500 focus:bg-white focus:ring-1
    py-2
    px-3">
    <p>{{ endDate.toLocaleDateString('pt') }}</p>
    </div>
  </div> 
</div>
<p *ngIf="input.key === 'toiEndDate' && !this.dateSelected" class=" w-6/12 text-secondary-500 text-center">{{'main-page.orders.measureUnits.range.time.' + measureUnits?.time | translate}}</p>
<p *ngIf="input.key === 'toiEndDate' && this.dateSelected" class=" w-4/12 text-secondary-500 text-center">{{'main-page.orders.measureUnits.range.time.' + measureUnits?.time | translate}}</p>
