import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-link',
  templateUrl: './navbar-link.component.html',
  styleUrls: ['./navbar-link.component.scss'],
})
export class NavbarLinkComponent implements OnInit {
  /**
   * Link label
   * @required
   */
  @Input()
  label = 'Link';
  /**
   * @required
   */
  @Input()
  route = '#';

  @Input()
  link = '#';

  @Input() id?: string;
  @Input() isMobile: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
