<label *ngIf="label" class="{{ isDisabled ? 'text-gray-400' : 'text-gray-700' }} font-medium"
  >{{ label }}
  <span *ngIf="required" class="text-red-500">*</span>
</label>
<ng-select
  class="wrapper mt-2"
  placeholder="{{ placeholder }}"
  [searchable]="searchable"
  [groupBy]="groupeBy"
  [items]="items"
  [readonly]="isDisabled"
  [markFirst]="markFirst"
  [multiple]="multiple"
  [clearable]="clearable"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [(ngModel)]="value"
  (change)="propagateSelected()"
>
</ng-select>
