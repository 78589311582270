import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from 'shared';
import { LoadingOrderItemComponent } from './components/loading-order-item/loading-order-item.component';
import { LoadingOrderPropertyComponent } from './components/loading-order-property/loading-order-property.component';
import { OrderItemDetailsComponent } from './components/order-item-details/order-item-details.component';
import { OrderItemComponent } from './components/order-item/order-item.component';
import { OrderPropertyItemComponent } from './components/order-property-item/order-property-item.component';
import { OrdersRoutingModule } from './orders-routing.module';
import { CreateOrderComponent, MyOrdersComponent, OrdersIndex } from './pages';

@NgModule({
  declarations: [
    MyOrdersComponent,
    OrdersIndex,
    CreateOrderComponent,
    OrderItemComponent,
    OrderPropertyItemComponent,
    LoadingOrderItemComponent,
    LoadingOrderPropertyComponent,
    OrderItemDetailsComponent,
  ],
  imports: [SharedModule, OrdersRoutingModule, FontAwesomeModule],
  exports: [OrderPropertyItemComponent]
})
export class OrdersModule {}
