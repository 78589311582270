import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {
  @Input() id: string = 'switch-input';
  @Input() leftLabel?: string;
  @Input() rightLabel?: string;
  @Input() value?: boolean;
  @Input() leftIcon?: IconDefinition;
  @Input() rightIcon?: IconDefinition;

  @Output() valueChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onValueChange(event: Event) {
    this.valueChange.emit(event);
  }

  isSelectedClass(isRight: boolean) {
    return { 'font-bold': isRight ? this.value : !this.value };
  }
}
