import { Component, Input, OnInit } from '@angular/core';
import { OrderStates, OrderStatus, TranslateService } from '@core';
@Component({
  selector: 'app-order-property-item',
  templateUrl: './order-property-item.component.html',
  styleUrls: ['./order-property-item.component.scss']
})
export class OrderPropertyItemComponent implements OnInit {
  @Input() label?: string = 'label';
  @Input() value?: string = 'value';
  @Input() blink?: boolean = false;
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

  getBlinkColor(status?: string): string {

    const colors:{[key in OrderStatus]:string} = {
      CREATED: 'bg-sky-300 text-sky-700',
      PENDING: 'bg-yellow-300 text-yellow-700',
      APPROVED: 'bg-violet-300 text-violet-700',
      FINISHED: 'bg-green-300 text-green-700',
      ERROR: 'bg-rose-300 text-rose-700',
      EMPTY: 'bg-sky-300 text-sky-700',
      PAYMENT_PENDING: 'bg-orange-300 text-orange-700',
      PAYMENT_PROCESSING: 'bg-blue-300 text-blue-700',
      SUBSCRIPTION_ACTIVE: 'bg-sky-300 text-sky-700'
    }

    if (status) return colors[OrderStatus[status as OrderStates]];

    return 'bg-gray-300 text-gray-700';
  }

  getStatusLabel(status?: string): string {
    const labels: {[key in OrderStatus]: string} = {
      CREATED: this.translate.translate('labels.order-status.created'),
      PENDING: this.translate.translate('labels.order-status.pending'),
      APPROVED: this.translate.translate('labels.order-status.approved'),
      FINISHED: this.translate.translate('labels.order-status.finished'),
      ERROR: this.translate.translate('labels.order-status.error'),
      EMPTY: this.translate.translate('labels.order-status.empty'),
      PAYMENT_PENDING: this.translate.translate('labels.order-status.payment-pending'),
      PAYMENT_PROCESSING: this.translate.translate('labels.order-status.payment-processing'),
      SUBSCRIPTION_ACTIVE: this.translate.translate('labels.order-status.subscription-active')
    }

    if (status) return labels[OrderStatus[status as OrderStates]];

    return labels['ERROR'];
  }

}
