<div *ngIf="instructions === null || instructions === undefined">
<h5 class="font-bold text-secondary-500 mb-2 mt-4">In order to submit an order,
  please enter:</h5>
<ul class="list-disc list-inside">
  <li>Time of interest in the start and end date;</li>
  <li>Area of interest by drawing it in the map;</li>
  <li>Additional parameters that the service might require;</li>
  <li> Press the order button;</li>
  <li>Check the results in the order section of the portal;</li>
</ul>
</div>
<div *ngIf="instructions !== null && arrayInstructions !== undefined ">
  <div *ngFor="let instruction of arrayInstructions">
    <ul class="list-disc list-inside">
      <li>{{instruction}}</li>
    </ul>
  </div>
  <p></p>
</div>
<div *ngIf="instructions !== null && arrayInstructions === undefined ">
    <div [innerHTML]="instructions" id="instruction"></div> 
</div>
