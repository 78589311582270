import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() photo: string = 'assets/img/backgrounds/newServices.jpg';
  @Input() label: string = 'Page Title';
  @Input() overLabel?: string;
  @Input() hasSearchBar: boolean = false;
  @Output() search = new EventEmitter();
  @Output() clear = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}


  onSearch(type?: string) {
    if(type === 'clear') this.clear.emit();
    else this.search.emit();
  }


}
