import { AfterViewInit, Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { MapService } from '@core';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, AfterViewInit {
  private readonly initialZoom: number = 5;
  private readonly center: [number, number] = [
    39.41922073655956, -8.195800781250002,
  ];

  @Input() id!: string;
  @Input() height?: string;
  @Input() classes?: string;
  @Input() multiFootprint?: boolean;
  @Input() numberOfFootprints?: number = 1;
  @Output() changeFootprintFocus = new EventEmitter<number>();

  focusedFootprintIndex: number = 0;

  faPlus = faChevronRight;
  faMinus = faChevronLeft;

  constructor(
      private mapServices: MapService,
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.mapServices.initializeMap('map', this.center, this.initialZoom);
      this.mapServices.replaceBasemap(
        this.mapServices.namesOfBaseMaps['Esri Satellite']
      );
    }, 0);
  }

  ngOnInit(): void {
    // this.mapServices.destroyMap();
  }

  ngOnDestroy(): void {
    this.mapServices.destroyMap();
  }

  handleDrawnBtnClick(action: string): void {
    this.mapServices.drawOnMap(action);
  }

  onFootprintFocusChange(offset: number) {
    this.focusedFootprintIndex += offset;
    this.changeFootprintFocus.emit(this.focusedFootprintIndex);
  }
}
