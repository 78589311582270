import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  public addParamsToUrl(paramId:string, value:string, route: ActivatedRoute ){
    this.router
      .navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { [paramId]:value},
        queryParamsHandling: 'merge',
      })
      .finally();
  }

  public removeParamsFromUrl(paramMap: ParamMap ,KeysToRemove:string[]){
      const queryParams:{[key:string]:string | null} = {};
      const keysToKeep = paramMap.keys.filter(k => !KeysToRemove.includes(k));
      keysToKeep.map(k => queryParams[k] = paramMap.get(k));
      this.router.navigate([], { queryParams, replaceUrl: true, relativeTo: this.activatedRoute }).finally();
  }
}
