import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentPayload } from '@core';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { environment } from '../../../../../environments';

@Component({
  selector: 'paypal-checkout',
  templateUrl: './paypal-checkout.component.html',
})
export class PayPalCheckoutComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;

  @Output() onApprove = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onError = new EventEmitter();
  @Output() onClick = new EventEmitter();
  @Output() onClientAuthorization = new EventEmitter();

  @Input() price?: string;

  ngOnInit(): void {
    this.initConfig();
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: 'EUR',
      clientId: environment.paypal_clientID,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'EUR',
                value: parseFloat(this.price as string).toFixed(2),
                breakdown: {
                  item_total: {
                    currency_code: 'EUR',
                    value: parseFloat(this.price as string).toFixed(2),
                  },
                },
              },
              items: [
                {
                  name: 'Enterprise Subscription',
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'EUR',
                    value: parseFloat(this.price as string).toFixed(2),
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onApprove: (_, actions) => {
        actions.order.get().then((details: any) => {

          const payload: PaymentPayload = {
            reference: details.id,
          }

          this.onApprove.emit(payload);
        });
      },
      onClientAuthorization: (data) => {
        this.onClientAuthorization.emit(data);
      },
      onCancel: (data, actions) => {
        this.onCancel.emit({ data, actions });
      },
      onError: (err) => {
        this.onError.emit(err);
      },
      onClick: (data, actions) => {
        this.onClick.emit({ data, actions });
      },
    };
  }
}
