import {Validators} from '@angular/forms';

export const contactsConfig = {
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    contact: [null, Validators.required],
    email: [null, Validators.required],
    address: [null, Validators.required],
    city: [null, Validators.required],
    state: [null, Validators.required],
    zip: [null, Validators.required],
    country: [null, Validators.required],
    birthday: [null, Validators.required],
    newsletter: [null]
}
   
