import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  CataloguePayload,
  Category,
  Collection,
  DomainArea,
  httpOptions,
  ListOfServices,
  Option,
  Processor,
  Publisher, Service, ServiceDescription, ServicePeriod
} from '@core';
import { Observable } from 'rxjs';
import { map, shareReplay, take, tap } from 'rxjs/operators';
import { serializeSelectOptions, sortByName } from 'shared';
import { environment } from '../../../../environments/environment';

type Parameter = {
    key: string;
    value: any;
};

@Injectable({
    providedIn: 'root',
})
export class ServicesService {
    serviceURL = `${environment.apiUrl}/v1`;

    constructor(private httpService: HttpClient) {
    }

    getAllCatalogueServices(parameters?: Array<Parameter>) {
        let serviceURL = `${this.serviceURL}/services`;
        if (parameters) {
            serviceURL = `${this.serviceURL}/services?`;
            parameters.forEach((parameter, index, listOfParameters) => {
                if (index === listOfParameters.length - 1) {
                    serviceURL = serviceURL.concat(parameter.key + '=' + parameter.value);
                } else {
                    serviceURL = serviceURL.concat(
                        parameter.key + '=' + parameter.value + '&'
                    );
                }
            });
        }
        return this.httpService.get<CataloguePayload>(serviceURL, httpOptions).pipe(
            take(1),
            map((payload: CataloguePayload) => {
                return payload;
            })
        );
    }

    getCatalogueServices(parameters?: string) {
        const params = parameters ? `?${parameters}` : '';
        return this.httpService
            .get<CataloguePayload>(`${this.serviceURL}/services${params}`, httpOptions)
            .pipe(
                shareReplay(1),
                take(1),
                map((payload) => payload)
            );
    }

    getAllCategories$() {
        let serviceURL = `${this.serviceURL}/domain-areas/categories?unpaged=true`;
        return this.httpService.get<ApiResponse<Category[]>>(serviceURL, httpOptions).pipe(
            shareReplay(1),
            take(1),
            map((payload: ApiResponse<Category[]>) => payload)
        );
    }

    getSelectableCategories() {
      return this.getAllCategories$()
        .pipe(
          shareReplay(1),
          take(1),
          map((payload: ApiResponse<Category[]>) => payload.rows)
        );
    }

    getAllDomainAreas$() {
      let serviceURL = `${this.serviceURL}/domain-areas?unpaged=true`;
      return this.httpService.get<ApiResponse<DomainArea[]>>(serviceURL, httpOptions).pipe(
          shareReplay(1),
          take(1),
          map((payload: ApiResponse<DomainArea[]>) => payload)
      );
    }

    getAllDomainAreasPublished$() {
      let serviceURL = `${this.serviceURL}/domain-areas/published?unpaged=true`;
      return this.httpService.get<ApiResponse<DomainArea[]>>(serviceURL, httpOptions).pipe(
          shareReplay(1),
          take(1),
          map((payload: ApiResponse<DomainArea[]>) => payload)
      );
    }

    getSelectableDomainAreas() {
      return this.getAllDomainAreas$()
        .pipe(
          shareReplay(1),
          take(1),
          map((payload: ApiResponse<DomainArea[]>) => payload.rows)
        );
    }

    getSelectableDomainAreasPublished() {
      return this.getAllDomainAreasPublished$()
        .pipe(
          shareReplay(1),
          take(1),
          map((payload: ApiResponse<DomainArea[]>) => payload.rows)
        );
    }

    getDomainAreaDetails(slug: string): Observable<DomainArea> {
      return this.httpService.get<DomainArea>(
          `${this.serviceURL}/domain-areas/${slug}`,
          httpOptions
      )
      .pipe(
        shareReplay(1)
      );
    }

    getServiceByIdentifier(serviceIdentifier: string): Observable<Service> {
        return this.httpService.get<Service>(
            `${this.serviceURL}/services/${serviceIdentifier}`,
            httpOptions
        ).pipe(shareReplay(1));
    }

    createNewService(service: Service): Observable<any> {
        return this.httpService.post(`${this.serviceURL}/services`, service, httpOptions)
    }

    getUserServices() {
        return this.httpService
            .get<ListOfServices>(`${this.serviceURL}/my-services`, httpOptions)
            .pipe(
                take(1),
                tap((service: ListOfServices) => service),
                map((service: ListOfServices) =>
                    service.sort((serviceA: Service, serviceB: Service) =>
                        sortByName(serviceA.title, serviceB.title)
                    )
                )
            );
    }

    getServiceDescription(identifier: string) {
        return this.httpService.get<ServiceDescription>(
            `${this.serviceURL}/services/${identifier}/description`,
            httpOptions
        );
    }

    getPublishers$(): Observable<Option[]> {
        return this.httpService.get<Publisher[]>(`${this.serviceURL}/publishers`, httpOptions).pipe(
            map(publishers => {
                return publishers.map((publisher: Publisher) => {
                    const opt: Option = {
                        label: publisher.name,
                        value: publisher.publish,
                        id: publisher.id
                    }
                    return opt;
                });
            })
        );
    }

    getAllCollections$() {
      return this.httpService
        .get<Collection[]>(`${this.serviceURL}/services/collections`, httpOptions)
        .pipe(
          take(1),
          map((data) => {
            const collections = data.map((collection) => {
              return { id: collection.type, name: collection.type };
            });

            return serializeSelectOptions(collections);
          })
        );
    }

    getAllProcessors$() {
        return this.httpService.get<Processor[]>(`${this.serviceURL}/services/processors`, httpOptions).pipe(
          take(1),
          map((data) => {
            const processors = data.map((processor) => {
              return { id: processor.identifier, name: processor.title };
            });

            return serializeSelectOptions(processors);
          })
        );
    }

    updateService(serviceIdentifier: string, updatedServiceData: Service, serviceType: string): Observable<any> {
      const url = `${this.serviceURL}/my-services/published/${serviceType}/${serviceIdentifier}`;
      return this.httpService.put(url, updatedServiceData, httpOptions);
    }

    getServiceMetadata(serviceIdentifier: string, serviceType: string): Observable<any> {
      const url = `${this.serviceURL}/my-services/published/${serviceType}/${serviceIdentifier}`;
      return this.httpService.get(url, httpOptions);
    }

    getServicePeriod(serviceIdentifier: string): Observable<ServicePeriod> {
      const url = `${this.serviceURL}/services/${serviceIdentifier}/service-period`;
      return this.httpService.get<ServicePeriod>(url, httpOptions);
    }


}
