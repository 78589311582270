import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IMyDateModel } from 'angular-mydatepicker';

@Injectable({
  providedIn: 'root'
})
export class DateCaldendarService {

  public defaultDate$: Subject<IMyDateModel> = new Subject();

  constructor() { }
}
