import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  MapService,
  ShapefileService,
  SnackbarService,
  NavigationService
} from '@core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faDrawPolygon,
  faFile,
  faICursor,
  faMapPin,
  faPencilAlt,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService as NgxTranslateService } from "@ngx-translate/core";
import * as turf from '@turf/turf';
import * as L from 'leaflet';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AreaOfInterestInput, convertAoiToBbox, convertSquareKMArea, handleAoiSize, isAoiBiggerThanMaxSize } from 'shared';
import * as shapefile from 'shpjs';
import { FeatureCollectionWithFilename } from 'shpjs';

import {
  convertFeatureToWK,
  convertToWK,
  parseFromWK
} from 'wkt-parser-helper';

@Component({
  selector: 'app-dynamic-input-aoi',
  templateUrl: './dynamic-input-aoi.component.html',
  styleUrls: ['./dynamic-input-aoi.component.scss'],
})
export class DynamicInputAOIComponent implements OnInit, OnDestroy {
  @Input() input: AreaOfInterestInput = new AreaOfInterestInput();
  @Input() form!: UntypedFormGroup;
  @Input() areaMeasureUnits: string | undefined;
  @Input() allowedGeometry: string | null | undefined;

  @Output() newArea = new EventEmitter<number>();

  
  componentDestroyed$: Subject<boolean> = new Subject();

  faDrawPolygon: IconDefinition = faDrawPolygon;
  faMapPin: IconDefinition = faMapPin;
  faPencil: IconDefinition = faPencilAlt;
  faICursor: IconDefinition = faICursor;
  faFile: IconDefinition = faFile;
  faSpinner: IconDefinition = faSpinner;
  isShowingWKT: boolean = false;
  isShowingPolygon: boolean = false;
  isShowingShapeFile: boolean = false;
  isShowingRectangle: boolean = false;
  isShapefile: boolean = false;
  @ViewChild('shapefileInput')
  shapefileInput!: ElementRef;
  isLoadingShapeFile: boolean = false;
  isMobile: boolean = false;
  isActive: boolean = false;
  isCopied: boolean = false;


  areaWKT: string | undefined;
  area: number | undefined;
  convertedArea: number | undefined;

  constructor(
    private mapService: MapService,
    private snackbarService: SnackbarService,
    private shapefileService: ShapefileService,
    private ngxTranslateService : NgxTranslateService,
    private navigation: NavigationService
  ) {}

  get isValid() {
    return this.form.controls[this.input.key].hasError('invalidAOI');
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.isActive = false;
    this.snackbarService.isSnackbarActive$.next(false);
  }

  ngOnInit(): void {
    this.mapService.drawingLayer$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((res) => {
        this.form.get('aoi')?.setValue(res?.wkt);
        this.areaWKT = res?.wkt;
        this.validateAOI();
        const input = this.form.controls[this.input.key];
        this.area = res?.area;
        if (res && res.area) {
          this.convertedArea = convertSquareKMArea(res.area, this.areaMeasureUnits);
        }
        this.isCopied = false;
        this.newArea.emit(res?.area);
        setTimeout(() => {
          input.markAsDirty();

          if (res?.wkt) input.markAsPristine();
        }, 0);
      });

      this.navigation.navigate$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(()=>{
        this.mapService.drawingLayer$.next(undefined);
      });
     
      window.onresize=()=>{
        
        if(window.innerWidth < 1024 && this.isMobile == false){
          
          this.isMobile = true;
          this.form.controls.aoi.setValue(null);
          this.navigation.onNavigate();
          
        }
       
        if(window.innerWidth > 1024 && this.isMobile == true){
          
          this.isMobile = false;
          this.form.controls.aoi.setValue(null);
          this.navigation.onNavigate();
        }
        
      }
      
            
      
  }

  onClick(type: string) {
    this.snackbarService.isSnackbarActive$.next(false);
    this.areaWKT = undefined;
    this.area = undefined;
    this.convertedArea = undefined;
    switch (type) {
      case 'wkt':
        this.isShowingWKT = true;
        this.isShowingPolygon = false;
        this.isShowingShapeFile = false;
        this.isShowingRectangle = false;
        break;
      case 'shapefile':
        this.isShowingWKT = false;
        this.isShowingPolygon = false;
        this.isShowingShapeFile = true;
        this.isShowingRectangle = false;
        break;
      case 'polygon':
        this.isShowingWKT = false;
        this.isShowingPolygon = true;
        this.isShowingShapeFile = false;
        this.isShowingRectangle = false;
        this.form.get('aoi')?.setValue(null);
        this.mapService.drawOnMap(type);
        break;
      case 'rectangle':
        this.isShowingWKT = false;
        this.isShowingPolygon = false;
        this.isShowingShapeFile = false;
        this.isShowingRectangle = true;
        this.form.get('aoi')?.setValue(null);
        this.mapService.drawOnMap(type);
        break;
      default:
        this.isShowingWKT = false;
        this.isShowingPolygon = false;
        this.isShowingShapeFile = false;
        this.isShowingRectangle = false;
        this.form.get('aoi')?.setValue(null);
        this.mapService.drawOnMap(type);
    }
  }

  insertWKT(event: Event) {
    let wkt = (event.target as HTMLInputElement).value;
    this.form.controls[this.input.key].setValue(wkt);
    this.validateAOI();
    let aoi = parseFromWK(wkt);
    let layer = L.geoJSON();
    layer.addData(aoi);
    this.calculateShapefileArea(layer);
    this.mapService.populateMap(aoi).finally();
  }

  onInput(event: Event) {
    this.area = undefined;
    this.convertedArea = undefined;
    this.isLoadingShapeFile = true;
    let files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      this.mapService.clearMap();
      this.form.get(this.input.key)?.setValue(null);
      this.form.updateValueAndValidity();
      this.shapefileService.readFileContent(files[0]).subscribe((file) => {
        shapefile(file as string).then((geoJSON) => {
          this.mapService.clearMap();
          let collection = geoJSON as FeatureCollectionWithFilename;
          let features = collection.features;
          let featureGroup = new L.FeatureGroup();
          features.forEach((feature, index) => {
            let wkt = convertFeatureToWK(feature);
            this.areaWKT = wkt;
            let aoi = parseFromWK(wkt) as GeoJSON.GeoJSON;
            if (this.allowedGeometry === null || this.allowedGeometry === aoi.type.toLowerCase()){
              let layer = L.geoJSON();
              layer.addData(aoi).on('click',(event) =>{
  
                this.form.controls[this.input.key].setValue(
                  convertFeatureToWK(event.propagatedFrom.feature)
                );
                this.calculateShapefileArea(layer);
  
              });
                
              featureGroup.setStyle({ color: '#052d74' });
              layer.setStyle({ color: '#f47d08' });
              this.validateAOI();
              this.shapefileInput.nativeElement.value = null;
              layer.addData(aoi);
              featureGroup.addLayer(layer);
            } else {
              this.isActive = false;
              this.snackbarService.isSnackbarActive$.next(false);
              this.snackbarService
                .danger(
              this.ngxTranslateService.instant('alerts.title.error'),
              this.ngxTranslateService.instant(
                'alerts.description.geometry-not-available'
              )
            )
            .during(4000)
            .show();
      
            this.form.controls[this.input.key].setErrors({ invalidAOI: true });
            this.form.controls[this.input.key].markAsDirty();
            }
          });
          featureGroup.setStyle({
            color: '#052d74',
          });
          this.mapService.populateMapWithFeatureGroup(featureGroup);
          this.isLoadingShapeFile = false;
        });
      });
    }
  }

  validateAOI() {
    if (
      this.input.serviceFootprint &&
      this.form.controls[this.input.key].value
    ) {
      if(parseFromWK(this.form.controls[this.input.key].value) as turf.Geometry){
        if (
          !turf.booleanWithin(
            parseFromWK(
              this.form.controls[this.input.key].value
            ) as turf.Geometry,
            parseFromWK(this.input.serviceFootprint) as turf.Geometry
          )
        ) {
          this.isActive = true;
          this.snackbarService
            .link(
              this.ngxTranslateService.instant('alerts.title.request-area'),
              this.ngxTranslateService.instant(
                'alerts.description.area-is-not-available.description'
              ),
              true
              

            )
            .during(this.isActive)
            .show();
          this.form.controls[this.input.key].setErrors({ invalidAOI: true });
          this.form.controls[this.input.key].markAsDirty();

         
        } else {
          let geometry = parseFromWK(this.form.controls[this.input.key].value) as turf.Geometry;
          if(this.allowedGeometry === null || this.allowedGeometry === geometry.type.toLowerCase()) {
            this.isActive = false;
            this.snackbarService.isSnackbarActive$.next(false);
          } else {
            this.isActive = false;
            this.snackbarService.isSnackbarActive$.next(false);
              this.snackbarService
                .danger(
              this.ngxTranslateService.instant('alerts.title.error'),
              this.ngxTranslateService.instant(
                'alerts.description.geometry-not-available'
              )
            )
            .during(4000)
            .show();
      
            this.form.controls[this.input.key].setErrors({ invalidAOI: true });
            this.form.controls[this.input.key].markAsDirty();
          }   
        }
      } else {
        this.isActive = false;
        this.snackbarService.isSnackbarActive$.next(false);
        this.snackbarService
            .danger(
              this.ngxTranslateService.instant('alerts.title.error'),
              this.ngxTranslateService.instant(
                'alerts.description.invalid-wkt'
              )
            )
            .during(2000)
            .show();
            this.form.controls[this.input.key].setErrors({ invalidAOI: true });
            this.form.controls[this.input.key].markAsDirty();
      }
    }
  }

  calculateShapefileArea(layer: any) {
    const targetKey = Object.keys(layer._layers)[0];
    const targetLayer = (layer as any)._layers[targetKey];
    const area = this.mapService.calculateArea(targetLayer);
    this.area = area;
    this.convertedArea = convertSquareKMArea(area, this.areaMeasureUnits);
    this.newArea.emit(area);
  }

  copyToClipboard() {
    if (this.areaWKT) {
      this.isCopied = true;
      navigator.clipboard.writeText(this.areaWKT);
    }
  }

  get areaUnitsLabel() {
    return this.areaMeasureUnits ?? 'KM';
  }

  disableButton(geometry: string): boolean{
      return !(this.allowedGeometry === null || this.allowedGeometry === geometry);
  }

  parseInt(convertedArea: string | null){
    
    if(convertedArea){
      convertedArea = convertedArea.split(',').join(' ');
      return convertedArea;
    }else{
      return null;
    }
   
  }
}

