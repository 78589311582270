<div [formGroup]="form" class="mt-4" xmlns="http://www.w3.org/1999/html">
  <app-label-secondary-color [label]="input.label"></app-label-secondary-color>
  <div class="flex flex-wrap flex-shrink-0 gap-4 mt-2 mb-4">
    <app-button
      [isSecondary]="true"
      (btnClick)="onClick('polygon')"
      [leftIcon]="faDrawPolygon"
      [label]="'actions.draw-polygon'|translate"
      [isDisabled]="disableButton('polygon')"
    ></app-button>
    <app-button
      [isSecondary]="true"
      (btnClick)="onClick('rectangle')"
      [leftIcon]="faDrawPolygon"
      [label]="'actions.draw-rectangle'|translate"
      [isDisabled]="disableButton('polygon')"
    ></app-button>
    <app-button
      [isSecondary]="true"
      (btnClick)="onClick('line')"
      [leftIcon]="faPencil"
      [label]="'actions.draw-line'|translate"
      [isDisabled]="disableButton('linestring')"
    ></app-button>
    <app-button
      [isSecondary]="true"
      (btnClick)="onClick('marker')"
      [leftIcon]="faMapPin"
      [label]="'actions.mark-point'|translate"
      [isDisabled]="disableButton('point')"
    ></app-button>
    
    <app-button
      [isSecondary]="true"
      (btnClick)="onClick('wkt')"
      [leftIcon]="faICursor"
      label="WKT"
    ></app-button>

    <app-button
      [isSecondary]="true"
      (btnClick)="onClick('shapefile')"
      [leftIcon]="faFile"
      label="Shapefile"
    ></app-button>


    <div
      *ngIf="isShowingShapeFile"
      class=" bg-gray-100 rounded-md w-full">
    <app-dynamic-tooltip
      [checkTool]="'shapefile'">
  
    </app-dynamic-tooltip>
    <div class="flex justify-end">
    <label
    class="
        
        bg-gradient-to-r
        from-primary-500 to-primary-600
        px-1
        py-1
        rounded
        text-gray-50
        transform
        duration-300
        justify-self-center
        cursor-pointer
        ease-in-out motion-safe:hover:scale-95 motion-safe:active:scale-90 active:shadow-inner
        uppercase
        font-medium
        w-44
        
      ">
      <p class="text-center">{{ "helper-texts.tooltip.shapefile.button" | translate }}</p>
      <input
      #shapefileInput
      (input)="onInput($event)"
      class="hidden"
      type="file"
      />
    </label>
  </div>
  </div>

   
    <app-dynamic-tooltip 
      class="w-full"
      [checkTool]="'wkt'"
      *ngIf="isShowingWKT"
    ></app-dynamic-tooltip>
    <app-dynamic-tooltip 
      class="w-full"
      *ngIf="isShowingPolygon"
      [checkTool]="'polygon'"
    ></app-dynamic-tooltip>
    <app-dynamic-tooltip 
      class="w-full"
      *ngIf="isShowingRectangle"
      [checkTool]="'rectangle'"
    ></app-dynamic-tooltip>
  </div>
    
  <input
    (blur)="insertWKT($event)"
    *ngIf="isShowingWKT"
    class="
      focus:outline-none
      block
      w-full
      rounded-md
      bg-gray-100
      border border-transparent
      focus:ring-primary-500 focus:bg-white focus:ring-1
      py-2
      px-3
      my-2
    "
  />
  <div class="flex flex-col gap-4">
    <div *ngIf="convertedArea" class="flex flex-col justify-between gap-2 border rounded-lg px-2 py-2 bg-gray-100">
      <span class="text-secondary-500 font-bold">{{ 'labels.area' | translate }}</span>
      <span>
        {{ parseInt(convertedArea| number: '1.0-2') }}
        {{ 'labels.area-units.' + areaUnitsLabel | translate }}</span>
    </div>
    <div *ngIf="areaWKT" class="flex flex-col justify-between gap-2 border rounded-lg px-2 py-2 bg-gray-100">
      <span class="text-secondary-500 font-bold">WKT</span>
      <div class="flex flex-row justify-between">
        <span>{{areaWKT}}</span>
        <p *ngIf="!isCopied" class="uppercase hover:cursor-pointer hover:bg-gray-300 hover:text-primary-500 text-secondary-500 pr-4"
          (click)="copyToClipboard()">{{ 'actions.copy' | translate }}</p>
        <p class="text-primary-500 uppercase pr-4" *ngIf="isCopied">{{ 'actions.copied' | translate }}!</p>
      </div>
    </div>
  </div>
</div>
