<div class="flex flex-col input-box-container mt-2">
	<app-label-secondary-color [label]="label" [helperText]="helperText" [isRequired]="true"></app-label-secondary-color>
	<input
			#dp="angular-mydatepicker"
			(click)="dp.toggleCalendar()"
			(dateChanged)="onDateChanged($event)"
			[(ngModel)]="model"
			[id]="id"
			[name]="name"
			[options]="myDpOptions"
			[defaultMonth]="defaultMonth"
      placeholder="Click to select a date"
			angular-mydatepicker
			onkeydown="return false"
			class="
      mt-1
      w-full
      rounded-md
      bg-gray-100
      border-transparent
      focus:border-gray-500 focus:bg-white focus:ring-0
      py-2
      px-3
    "
  />
</div>
