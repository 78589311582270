import { Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { addMonths, addYears, differenceInMonths, differenceInYears, format } from 'date-fns';
import { MeasureUnits } from '@core';

@Component({
  selector: 'app-dynamic-range-input',
  templateUrl: './dynamic-range-input.component.html',
  styleUrls: ['./dynamic-range-input.component.scss']
})
export class DynamicRangeInputComponent implements OnInit, OnChanges {

  @Input() helperText: string;
  @Input() input: any;
  @Input() form: any;
  @Input() measureUnits: MeasureUnits | undefined;
  @Input() label: string = 'datePickerID';
  @Input() dateSelected: string;
  @Input() maxServiceOrderLimit: number | undefined;
  @Input() minServiceOrderLimit: number | undefined;
  @Input() isSubscription?: boolean;
  

  value: number = 1;
  endDate: Date;
  newMaxLimitDate: number | undefined;
  newMinLimitDate: number | undefined;



  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    let inputValue = this.value;
    let startDate = new Date(this.form.get('toiStartDate')?.value);

    if (this.isSubscription) {
      this.newMinLimitDate = 1;
      this.newMaxLimitDate = undefined;
      if (this.measureUnits?.time === 'MONTH') {
        
        if (this.maxServiceOrderLimit !== undefined) {
          this.newMaxLimitDate = this.maxServiceOrderLimit;
        }
        if (this.minServiceOrderLimit !== undefined && this.minServiceOrderLimit > 1) {
          this.newMinLimitDate = this.minServiceOrderLimit;
        }
        this.endDate = addMonths(startDate, inputValue);
      } else {
        
        if (this.maxServiceOrderLimit !== undefined) {
          this.newMaxLimitDate = this.maxServiceOrderLimit;
        }
        if (this.minServiceOrderLimit !== undefined && this.minServiceOrderLimit > 1) {
          this.newMinLimitDate = this.minServiceOrderLimit;
        }
        this.endDate = addYears(startDate, inputValue);
      }
      this.setDateFormat('toiEndDate');
    } else {
      let newEndDate = new Date(this.input.serviceEndDate);

      if (this.measureUnits?.time === 'MONTH') {
  
        if (this.maxServiceOrderLimit !== undefined) {
          const interval = differenceInMonths(newEndDate, startDate);
          if (interval < this.maxServiceOrderLimit) {
            this.newMaxLimitDate = Math.floor(interval);
          } else {
            this.newMaxLimitDate = this.maxServiceOrderLimit;
          }
          if (inputValue > this.newMaxLimitDate) {
            inputValue = this.newMaxLimitDate;
            this.value = inputValue;
          }
        } else {
          this.newMaxLimitDate = Math.floor(differenceInMonths(newEndDate, startDate));
        }
        if (this.minServiceOrderLimit === undefined) {
          this.newMinLimitDate = 1;
        } else {
          this.newMinLimitDate = this.minServiceOrderLimit;
          if (inputValue < this.newMinLimitDate) {
            this.value = this.newMinLimitDate;
            inputValue = this.newMinLimitDate;
          }
        }
        this.endDate = addMonths(startDate, inputValue);
        this.setDateFormat('toiEndDate');
      } else {
        
        if (this.maxServiceOrderLimit === undefined) {
          this.newMaxLimitDate = Math.floor(differenceInYears(newEndDate, startDate));
        } else {
          const interval = differenceInYears(newEndDate, startDate);
          if (interval < this.maxServiceOrderLimit) {
            this.newMaxLimitDate = Math.floor(interval);
          } else {
            this.newMaxLimitDate = this.maxServiceOrderLimit;
          }
          if (inputValue > this.newMaxLimitDate) {
            inputValue = this.newMaxLimitDate;
            this.value = inputValue;
          }      
        }
        if (this.minServiceOrderLimit === undefined) {
          this.newMinLimitDate = 1;
        } else {
          this.newMinLimitDate = this.minServiceOrderLimit;
          if (inputValue < this.newMinLimitDate) {
            this.value = this.newMinLimitDate;
            inputValue = this.newMinLimitDate;
          }
        }
        this.endDate = addYears(startDate,inputValue);
        this.setDateFormat('toiEndDate');
      }
  
      const curretnEndDate = new Date (this.form.get('toiEndDate')?.value);
      if(curretnEndDate >= newEndDate) {
        this.endDate = newEndDate;
      }
    }



  }

  inputChange(event: any) {
    let choosenNumber = event.target.value;
    let startDate = new Date (this.form.get('toiStartDate')?.value);

    if (this.isSubscription) {
      this.newMinLimitDate = 1;
      this.newMaxLimitDate = undefined;

      if (this.measureUnits?.time === 'MONTH') {
        if (this.maxServiceOrderLimit !== undefined) {
          this.newMaxLimitDate = this.maxServiceOrderLimit;
          if (choosenNumber > this.newMaxLimitDate) {
            choosenNumber = this.newMaxLimitDate;
          }
        }
        if (this.minServiceOrderLimit !== undefined && this.minServiceOrderLimit > 1) {
          this.newMinLimitDate = this.minServiceOrderLimit;
        }
        if (choosenNumber < this.newMinLimitDate) {
          this.value = this.newMinLimitDate;
          choosenNumber = this.newMinLimitDate;
        }

        this.endDate = addMonths(startDate, choosenNumber);
      } else {
        if (this.maxServiceOrderLimit !== undefined) {
          this.newMaxLimitDate = this.maxServiceOrderLimit;
          if (choosenNumber > this.newMaxLimitDate) {
            choosenNumber = this.newMaxLimitDate;
          }
        }
        if (this.minServiceOrderLimit !== undefined && this.minServiceOrderLimit > 1) {
          this.newMinLimitDate = this.minServiceOrderLimit;
        }
        if (choosenNumber < this.newMinLimitDate) {
          this.value = this.newMinLimitDate;
          choosenNumber = this.newMinLimitDate;
        }
        this.endDate = addYears(startDate,choosenNumber);
      }

      this.setDateFormat('toiEndDate');

    } else {
      let newEndDate = new Date(this.input.serviceEndDate);
      
      if (this.measureUnits?.time === 'MONTH') {
        
        if (this.maxServiceOrderLimit !== undefined) {
          const interval = differenceInMonths(newEndDate, startDate);
          if (interval < this.maxServiceOrderLimit) {
            this.newMaxLimitDate = Math.floor(interval);
          } else {
            this.newMaxLimitDate = this.maxServiceOrderLimit;
          }
          if (choosenNumber > this.newMaxLimitDate) {
            choosenNumber = this.newMaxLimitDate;
            event.target.value = choosenNumber;
            this.value = choosenNumber;
          }
        } else {
          this.newMaxLimitDate = Math.floor(differenceInMonths(newEndDate, startDate));
        }
        if (this.minServiceOrderLimit === undefined) {
          this.newMinLimitDate = 1;
        } else {
          this.newMinLimitDate = this.minServiceOrderLimit;
        }
        this.endDate = addMonths(startDate, choosenNumber);
        this.setDateFormat('toiEndDate');
      } else {

        if (this.maxServiceOrderLimit === undefined) {
          this.newMaxLimitDate = Math.floor(differenceInYears(newEndDate, startDate));
        } else {
          const interval = differenceInYears(newEndDate, startDate);
          if (interval < this.maxServiceOrderLimit) {
            this.newMaxLimitDate = Math.floor(interval);
          } else {
            this.newMaxLimitDate = this.maxServiceOrderLimit;
          }
          if (choosenNumber > this.newMaxLimitDate) {
            choosenNumber = this.newMaxLimitDate;
            event.target.value = choosenNumber;
            this.value = choosenNumber;
          }      
        }
        this.endDate = addYears(startDate,choosenNumber);

        if (this.minServiceOrderLimit === undefined) {
          this.newMinLimitDate = 1;
        } else {
          this.newMinLimitDate = this.minServiceOrderLimit;
        }
        this.setDateFormat('toiEndDate');
        
      }
      const curretnEndDate = new Date (this.form.get('toiEndDate')?.value);
      if (curretnEndDate >= newEndDate)  {
        this.endDate = newEndDate;
      } 
    }
    
    
  }
  
  setDateFormat(control: string) {
   if (this.dateSelected) {
    this.form.controls[control].setValue(format(new Date(this.endDate), "yyyy-MM-dd\'T\'HH:mm:ss"));
   }
  }

  ignoreFloat(event:KeyboardEvent){
    
    return !isNaN(parseInt(event.key));
  }

}
