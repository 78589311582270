import { Routes } from '@angular/router';
import { UnauthorizedLoadGuard } from '@core/guards';
import { BuyComponent } from 'features/customer';
import { NgxPermissionsGuard } from 'ngx-permissions';

export const BuyRoutes: Routes = [
  {
  
    path: '',
    component: BuyComponent,
    data: {
     
    },
  },
];
