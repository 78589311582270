<div class="text-white bg-primary-500 lg:ml-6 flex flex-col lg:rounded-b-lg " 
    [ngClass]="{'hidden': !(isShowingSelect && wmsInfo !== undefined && wmsInfo.length !==0) }">
    <div class="flex flex-row text-center pl-2 pt-1">
        <button (click)="btnClicked()">
            <fa-icon [icon] = "faXmark" size="lg"></fa-icon>
        </button>
        <p class="w-full pr-4">{{'wmsInfo.title' | translate}}</p>
    </div>
    
    <div class="pb-2 px-2">
        <app-checkbox-select [list]="wmsInfo" (shareCheckedList)="checkWmsItem($event)">
            
        </app-checkbox-select>
       
    </div>
</div>


<div class="text-white bg-primary-500 lg:ml-6 lg:rounded-b-lg" 
    *ngIf="!isShowingSelect && this.wmsInfo !== undefined  && this.wmsInfo.length !== 0">
    <button (click)="btnClicked()">
        <fa-icon [icon] = "faChevronDown" class="p-2"></fa-icon>
    </button>
</div>
