import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { httpOptions } from '@core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {
  CurrencyCode,
  Order,
  PriceInfo,
  PriceModel, Service
} from '../../models';


@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  paymentURL = `${environment.apiUrl}/v1/order`;
  constructor(private httpService: HttpClient) {}

  options(order: Order, service: Service, countryCode?: string, userVAT?: number) {
    let { aoi, beginAt, endAt } = order;
    const { identifier, unitPrice, measureUnits, priceModel } = service;
    
    if (typeof endAt === 'string') {
      (endAt as string) = (endAt as string).replace('T00:00:00', 'T23:59:59');
    } else if (endAt instanceof Date) {
      endAt.setHours(23, 59, 59);
    }

    switch (priceModel) {
      case PriceModel.BASED_ON_AOI:
        return {
          serviceID: identifier,
          currency: CurrencyCode.EUR,
          aoi: {
            wkt: aoi,
            unitOfMeasure: measureUnits?.area,
            price: unitPrice?.area,
          },
          countryCode: countryCode ?? null,
          vatPercentage: userVAT ?? null
        };
      case PriceModel.BASED_ON_TOI:
        return {
          serviceID: identifier,
          currency: CurrencyCode.EUR,
          toi: {
            start: beginAt,
            stop: endAt,
            unitOfMeasure: measureUnits?.time,
            price: unitPrice?.time,
          },
          countryCode: countryCode ?? null,
          vatPercentage: userVAT ?? null
        };
      case PriceModel.BASED_ON_TOI_AND_AOI:
        return {
          serviceID: identifier,
          currency: CurrencyCode.EUR,
          aoi: {
            wkt: aoi,
            unitOfMeasure: measureUnits?.area,
            price: unitPrice?.area,
          },
          toi: {
            start: beginAt,
            stop: endAt,
            unitOfMeasure: measureUnits?.time,
            price: unitPrice?.time,
          },
          countryCode: countryCode ?? null,
          vatPercentage: userVAT ?? null
        };
      default:
        return {
          serviceID: identifier,
          currency: CurrencyCode.EUR,
          countryCode: countryCode ?? null,
          vatPercentage: userVAT ?? null
        };
    }
  }

  calculatePrice(
    order: Order,
    service: Service,
    countryCode?: string,
    userVAT?: number
  ): Observable<PriceInfo> {
    const payload = this.options(order, service, countryCode, userVAT);

    return this.httpService
      .post<PriceInfo>(this.paymentURL + '/pricing', payload, httpOptions)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          throw new Error(err.error);
        })
      );
  }
}
