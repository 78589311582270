<div class="disable-background" *ngIf="ordersDisabled"></div>
<ng-container [ngSwitch]="geoRecordSubType" class="mb-16">
  <ng-container *ngSwitchCase="'Direct_Download'">
    <div class="flex">
      <app-dynamic-form 
      [isPriceGenerated]="true"
      (submitForm)="
          emitData({
            beginAt: null,
            endAt: null,
            aoi: null,
            serviceId: geoRecordType?.toString(),
            inputs: []
          })
        "
      class="w-full"
      [termsURI]="service.documentationURI"
      [maxServiceOrderLimit]="service.serviceOrderLimits?.time?.max"
      [minServiceOrderLimit]="service.serviceOrderLimits?.time?.min"
    >
  </app-dynamic-form>
      
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div
      class="w-full rounded-md mx-auto mt-20 "
      *ngIf="isLoading; else isLoaded"
    >
      <div
        class="flex animate-pulse flex-row items-center h-full justify-center space-x-5"
      >
        <div class="flex flex-col space-y-3 ">
          <div class="w-36 bg-gray-300 h-6 rounded-md"></div>
          <div class="w-24 bg-gray-300 h-6 rounded-md"></div>
        </div>
      </div>
    </div>
    <ng-template #isLoaded>
      <app-dynamic-form
        (window:resize)="resetForm()"
        (aoiToiChange)="onFormValuesChange($event)"
        (submitForm)="emitData($event)"
        [isLoading]="isOrderLoading"
        [inputs]="inputs$ | async"
        [isPriceGenerated]="isServicePaid ? !!payment?.totalPrice : true"
        [termsURI]="service.documentationURI"
        [geoRecordSubType]="geoRecordSubType"
        [timeService]="service.serviceOrderLimits?.time"
        [areaService]="service.serviceOrderLimits?.area"
        (areaExtRequest)="onRequest()"
        [unitPrice]="service.unitPrice"
        [measureUnits]="service.measureUnits"
        [userData]="userData | async"
        [allowedGeometry]="service.allowedGeometry"
        [servicePeriod]="servicePeriod"
        [timeSteps]="service.timeStep ?? []"
        [hasSubscriptionEnabled]="service.isSubscription"
      >
        <div class="grid grid-cols-2 place-content-center">
          <hr class="col-span-12 border-dashed border-secondary-400 w-full my-4" />

          <div
            *ngIf="isServicePaid; else isFree"
            class="col-span-12 flex justify-between p-2"
          >
            <p class="text-secondary-500 font-bold text-md uppercase"
              >{{ "main-page.orders.estimated-price" | translate }}:</p
            >
            <div *ngIf="isPriceLoading; else priceLoaded">
              <p class="text-gray-400 animate-pulse">{{ 'actions.loading.calculate' | translate }}</p>
            </div>
            <ng-template #priceLoaded>
              <div *ngIf="payment; else noPriceDefined" class="flex flex-col gap-1">
                <div class="flex flex-row gap-1 justify-between">
                  <span>{{ "main-page.orders.base" | translate }}: </span>
                  <span>{{ payment.basePrice | currency: "EUR " : "code" }}</span>
                </div>
                <div class="flex flex-row gap-1 justify-between">
                  <span>{{ "main-page.orders.vat" | translate }} ({{ payment.vat?.rate }}%): </span>
                  <span>{{ payment.vat?.amount | currency: "EUR " : "code" }}</span>
                </div>
                <div class="flex flex-row gap-1 justify-between border-t-2 border-dashed">
                  <span><b>{{ "main-page.orders.total" | translate }}: </b></span>
                  <span>{{ payment.totalPrice | currency: "EUR " : "code"}}</span>
                </div>
              </div>
              <!-- <p *ngIf="payment; else noPriceDefined"> {{ payment.totalPrice ? (payment.totalPrice | currency: "EUR ": "code") : 'labels.free' | translate }} </p> -->
              <ng-template #noPriceDefined>
                <p class="text-red-500"> {{ "errors.price-undetermined" | translate }}</p>
              </ng-template>
            </ng-template>

          </div>

          <ng-template #isFree>
            <div
              class="col-span-4 p-2 bg-green-300 border border-green-200 rounded mt-4 w-full h-fit"
            >
              <span class="text-green-700 font-bold"> Free service </span>
            </div>
          </ng-template>

        </div>
      </app-dynamic-form>
    </ng-template>
  </ng-container>
</ng-container>
