import { Routes } from "@angular/router";
import { UnauthorizedLoadGuard } from "@core/guards";
import { NgxPermissionsGuard } from "ngx-permissions";

export const CustomerRoutes: Routes = [
    {
        path: 'marketplace',
        loadChildren:() => import('../../features/customer/modules/shop/shop.module').then( m => m.ShopModule)
    },
    {
        canLoad: [UnauthorizedLoadGuard],
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: ['customer', 'seller'],
                redirectTo: 'home',
            },
        },
        path: 'orders',
        loadChildren: () => import('../../features/customer/modules/orders/orders.module').then(m => m.OrdersModule)
    }
]
