import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private gtag: GoogleAnalyticsService) { }

  private emitAnalyticsEvent(action: string, category: string, label: string) {
    this.gtag.event(action, category, label);
  }

  private emitAnalyticsPageView(path: string, pageName: string) {
    this.gtag.pageView(path, pageName);
  }

  emitServiceViewEvent(serviceId: string, serviceName: string) {
    this.emitAnalyticsEvent('service_view', serviceId, serviceName);
  }

  emitPageViewEvent(pageName: string, route: string) {
    this.emitAnalyticsPageView(route, pageName);
  }

  emitButtonClickedEvent(buttonLocation: string, buttonName: string) {
    this.emitAnalyticsEvent('button_clicked', buttonLocation, buttonName);
  }
  
  emitOrderEvent(serviceId: string, orderType: string) {
    this.emitAnalyticsEvent('order_placed', orderType, `ServiceID: ${serviceId}`);
  }

  emitFailedOrderEvent(serviceId: string, orderType: string) {
    this.emitAnalyticsEvent('order_failed', orderType, `ServiceID: ${serviceId}`);
  }

  emitPaymentEvent(serviceId: string, paymentMethod: string) {
    this.emitAnalyticsEvent('payment', paymentMethod, serviceId);
  }

  emitFailedPaymentEvent(serviceId: string, paymentMethod: string) {
    this.emitAnalyticsEvent('payment_failed', paymentMethod, serviceId);
  }

  emitVideoPlayEvent(videoUrl: string) {
    this.emitAnalyticsEvent('play_video', 'resources_video', videoUrl);
  }
}
