import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, UntypedFormGroup } from "@angular/forms";
import { advancedSearchForm, FilterService, searchForm } from "@core";
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faClose, faGlobeEurope, faICursor, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Subject } from "rxjs";
import { isStringEmpty } from "shared/helpers";
@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {

  @Output() searchEvent = new EventEmitter<string>()
  @Output() loadingEvent = new EventEmitter<boolean>()

  faSearch: IconDefinition = faSearch;
  faGlobeEurope: IconDefinition = faGlobeEurope;
  faICursor: IconDefinition = faICursor;
  faClose = faClose;

  isAdvancedSearch: boolean = false;

  forms: { [key: string]: UntypedFormGroup } = {
    simple: this.formBuilder.group(searchForm),
    advanced:this.formBuilder.group(advancedSearchForm)
  }
  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private filterService: FilterService
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onSubmit() {
    let values:{[key: string]: string} = this.forms['advanced'].value;
    for(let key in values){
      if(values[key]){
        this.filterService.onSearch(key, values[key])
      }else{
        this.filterService.onSearch(key)
      }
    }
    this.searchEvent.emit('search')
  }

  onSearchSubmit() {
    const { search } = this.forms['simple'].value;
    if (!isStringEmpty(search)) {
      this.filterService.onSearch("search", search)
      this.searchEvent.emit('search')
    }
  }

  handleSearchType() {
    if (this.forms['simple'].touched || this.forms['advanced'].touched) this.clearFilter();

    this.isAdvancedSearch = !this.isAdvancedSearch;
  }

  clearForms(key: string) {
    const form = this.forms[key];

    for(let key in form.value)
    form.patchValue({ [key]: [null] })

    form.reset();
  }

  clearFilter(){
    this.clearForms('simple');
    this.clearForms('advanced');
    this.filterService.onFilterClear()
    this.searchEvent.emit('clear')
  }

  get isFilterEmpty() {
    return this.filterService.isEmpty()
  }
}
