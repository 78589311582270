import { Component, forwardRef, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-rating-input',
  templateUrl: './rating-input.component.html',
  styleUrls: ['./rating-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RatingInputComponent),
      multi: true,
    },
  ],
})
export class RatingInputComponent implements OnInit, ControlValueAccessor {
  @Input('value') _value: number = 0;

  constructor() { }

  onChange = (value: number) => {};
  onTouched = () => {};

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onTouched();
  }

  writeValue(value: number): void {
   this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {
  }

  evaluationRatingConvertToArray(lenght: number): any[] {
    return Array.from(Array(lenght).keys())
  }

  onValueChange(value: number) {
    const rating = value + 1;
    this.value = rating;
    this.onChange(rating);

  }

}
