import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MapService } from '@core';

@Component({
  selector: 'app-map-thumbnail',
  templateUrl: './map-thumbnail.component.html',
  styleUrls: ['./map-thumbnail.component.scss']
})
export class MapThumbnailComponent implements OnInit {

  @Input() aoiGeoJSON: GeoJSON.GeoJSON | undefined;
  @Input() orderID: number;
  @Output() mapDestroyed: EventEmitter<boolean> = new EventEmitter();

  private readonly initialZoom: number = 5;
  private readonly center: [number, number] = [
    39.41922073655956, -8.195800781250002,
  ];

  constructor(
    private mapServices: MapService,
  ) {}


  ngAfterViewInit(): void {

    setTimeout(() => {
      this.mapServices.initializeMap(`map-thumbnail-${this.orderID}`, this.center, this.initialZoom);
      this.mapServices.replaceBasemap(
        this.mapServices.namesOfBaseMaps['Esri Satellite']
      );
      if (this.aoiGeoJSON && this.aoiGeoJSON !== null) {
        this.mapServices.setFootprint(this.aoiGeoJSON).finally();
      }
    }, 200);
  }

  ngOnInit(): void {
    // this.mapServices.destroyMap();
    this.mapDestroyed.emit(true);
  }

  ngOnDestroy(): void {
    this.mapServices.destroyMap();
  }

}
