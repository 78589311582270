<div class="flex flex-col align-top" [ngClass]="wrapperClass()">
  <label [for]="id" class="{{isDisabled?'text-gray-400':'text-gray-700'}} font-medium" *ngIf="label">
    {{ label }}
    {{ currency }}
    <span *ngIf="required" class="text-red-500">*</span>
    <fa-icon
      *ngIf="helperText"
      [icon]="faInfoCircle"
      [title]="helperText"
      class="text-xs text-secondary-300"
    ></fa-icon>
  </label>
  <input
    *ngIf="inputType != 'dropdown'"
    [autocomplete]="autocomplete"
    class="
      form-input
      focus:outline-none
      block
      w-full
      py-2
      px-3
      rounded-md
      border border-transparent
      max-h-[40px]
      focus:ring-1
      focus:ring-primary-400
      focus:border-primary-400
      caret-primary-500
      {{error?'bg-red-200':'bg-gray-100'}}
    "
    [ngClass]="inputClass"
    [id]="id"
    [placeholder]="placeholder"
    [type]="inputType"
    [step]="inputStep"
    [disabled]="isDisabled"
    [(ngModel)]="value"
    [maxLength]="maxLength"
    [pattern]="pattern?pattern:''"
    (ngModelChange)="handleInputChange($event)"
    [min]="numberMin"
    [max]="numberMax"
    (keypress)="isInputStepInteger ? ignoreFloat($event) : ''"
  />

  <ng-select *ngIf="inputType == 'dropdown'" [id]="id" [(ngModel)]="value">
    <ng-option *ngFor="let opt of options" [value]="opt.key">
      {{opt.value}}
    </ng-option>
  </ng-select>


</div>
