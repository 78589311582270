import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BuyRoutes } from '@core/routes/buy.routes';
import { HomeRoutes } from '@core/routes/home.routes';
import { AppComponent } from 'app.component';
import { CustomerRoutes } from "./@core/routes/customer.routes";
import { SellerRoutes } from "./@core/routes/seller.routes";
import { MainLayoutComponent, PageNotFoundComponent } from "./shared";

const routes: Routes = [
  { path: 'callback', redirectTo: 'home', pathMatch: 'full', },
  { path: 'sign-out', redirectTo: 'home', pathMatch: 'full', },
  {path: '', redirectTo:'home', pathMatch: 'full',},
  {
    path: 'home',
    component: MainLayoutComponent,
    children: HomeRoutes
  },
  {
    path: 'sellers',
    component:MainLayoutComponent,
    children: SellerRoutes
  },
  {
    path: 'customers',
    component: MainLayoutComponent,
    children: CustomerRoutes
  },
  {
    path: 'customers/marketplace/:id/buy',
    component: AppComponent,
    children: BuyRoutes
  },

  { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
