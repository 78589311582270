const sortByName = (titleA: string, titleB: string): number => {
  if (titleA > titleB) {
    return 1;
  }
  if (titleA < titleB) {
    return -1;
  }
  return 0;
}

export {sortByName}
