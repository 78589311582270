<div class="grid grid-cols-12" *ngIf="!isAdvancedSearch; else advancedSearch">
  <form
    [formGroup]="forms.simple"
    (ngSubmit)="onSearchSubmit()"
    class="flex gap-5 items-end col-span-12 md:col-span-10"
  >
    <app-input
      class="w-full"
      id="search-input"
      formControlName="search"
      [placeholder]="'placeholders.type-to-search' | translate"
      [label]="'input-labels.free-text' | translate"
      [focusRig]="true"
    ></app-input>
    <button
        *ngIf="!isFilterEmpty"
        class="text-danger-500 text-sm p-2"
        type="button"
        (click)="clearFilter()"
      >
        <fa-icon [icon]="faClose"></fa-icon>
      </button>
    <app-button
      [isDisabled]="forms.simple.invalid"
      [leftIcon]="faSearch"
      [label]="'button-labels.search' | translate"
      type="submit"
    ></app-button>
  </form>
  <app-icon-button
    class="flex col-span-12 md:col-span-2 items-end md:justify-end justify-center"
    [icon]="faGlobeEurope"
    hover="hover:bg-gray-100 rounded"
    [label]="'button-labels.advanced-search' | translate"
    textColor="text-secondary-500 hover:text-primary-500"
    type="button"
    (btnClick)="handleSearchType()"
  ></app-icon-button>
</div>
<ng-template #advancedSearch>
  <form
    (ngSubmit)="onSubmit()"
    [formGroup]="forms.advanced"
    class="grid grid-cols-12 md:gap-5"
  >
    <div class="col-span-9 grid grid-cols-12 md:gap-5">
      <app-input
        formControlName="aoi"
        class="col-span-12 md:col-span-6"
        [placeholder]="'placeholders.insert-wkt' | translate"
        [label]="'input-labels.wkt' | translate"
        [helperText]="'helper-text.wkt' | translate"
      ></app-input>
      <app-input
        id="beginDate"
        class="col-span-12 md:col-span-3"
        formControlName="beginDate"
        inputType="date"
        [label]="'input-labels.startDate' | translate"
      ></app-input>
      <app-input
        id="endDate"
        class="col-span-12 md:col-span-3"
        formControlName="endDate"
        inputType="date"
        [label]="'input-labels.endDate' | translate"
      ></app-input>
    </div>
    <div class="col-span-12 md:col-span-3 flex items-end justify-between">
      <button
        *ngIf="!isFilterEmpty"
        class="text-danger-500 text-sm p-2"
        type="button"
        (click)="clearFilter()"
      >
        <fa-icon [icon]="faClose"></fa-icon>
      </button>
      <app-button
        [isDisabled]="forms.advanced.invalid"
        [leftIcon]="faSearch"
        [label]="'button-labels.search' | translate"
        type="submit"
      ></app-button>
      <app-icon-button
        [icon]="faICursor"
        hover="hover:bg-gray-100 rounded"
        [label]="'button-labels.text-search' | translate"
        textColor="text-secondary-500 hover:text-primary-500"
        type="button"
        (btnClick)="handleSearchType()"
      ></app-icon-button>
    </div>
  </form>
</ng-template>
