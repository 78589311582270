<button
    [disabled]="isDisabled"
    [type]="type"
		(click)="onClick()"
    [ngClass]="disabledClass()"
		class="py-2 px-4 {{textColor}} {{hover}} {{background}}">
	<fa-icon [icon]="icon" [size]="iconSize" [ngClass]="iconNgClass"></fa-icon>
	<span class="{{ label && ' ml-2' }}">
    {{ label }}
  </span>
</button>
