import {DynamicInputBase} from './dynamic-input-base';
import {IDynamicInputTOI} from '../../../../../../@core';

export class TimeOfInterestInput extends DynamicInputBase<any> {
  controlType = 'TOI';
  serviceStartDate?: Date;
  serviceEndDate?: Date;


  constructor(options: IDynamicInputTOI = {}) {
    super(options);
    this.serviceStartDate = options.serviceStartDate;
    this.serviceEndDate = options.serviceEndDate;
  }
}
