<div class="mt-2">
  <form class="mb-6" [formGroup]="evaluationForm" (ngSubmit)="onSubmit($event)" *ngIf="!userHasComment">
    <div class="p-2 inline-flex items-center bg-white  rounded-lg">
      <label for="'placeholders.evaluation' | translate">{{ 'placeholders.evaluation' | translate }}</label>
      <app-rating-input formControlName="rating" id="rating" name="rating"></app-rating-input>
    </div>
    <div class="py-2 px-4 mb-4 mt-2 bg-white rounded-lg rounded-t-lg border border-gray-200">
      <textarea
        id="comment"
        name="comment"
        rows="6"
        class="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none"
        [placeholder]="'placeholders.evaluation-textfield' | translate"
        formControlName="comment"
        required
      ></textarea>
    </div>
    <button
      type="submit"
      class="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-primary-500 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-600"
    >
      {{ 'actions.post-your-evaluation' | translate }}
    </button>
  </form>
  <!-- LIST EVALUATIONS -->
  <div *ngFor="let evaluation of (serviceEvaluations$ | async)?.data">
    <article class="p-6 mb-6 text-base bg-white rounded-lg border border-gray-100">
      <footer class="flex justify-between items-center mb-2">
        <div class="flex items-center">
          <div
            class="inline-flex items-center mr-3 text-sm text-gray-900"
          >
            <button
              class = "
                bg-gradient-to-r
                from-primaryGradient-right
                to-primaryGradient-left
                rounded-full
                h-7
                w-7
                transform
                duration-300
                cursor-default
                mr-1
              "
            >
              <p class = "m-0 text-gray-50 font-bold text-xs">
                {{ getInitials(evaluation.attributes.userName) }}
              </p>
            </button>
            {{ evaluation.attributes.userName }}
            <h6 class="text-xs font-bold ml-1 text-primary-400" *ngIf="evaluation.attributes.inum == userData.inum">(You)</h6>
          </div>
          <p class="text-sm text-gray-600">
            <time pubdate title="{{ dateFormat(evaluation.attributes.createdAt, 'MMM. dd, yyyy') }}">
              {{ dateFormat(evaluation.attributes.createdAt, "MMM. dd, yyyy") }}
            </time>
          </p>
        </div>
        <div
          class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-400 bg-white rounded-lg focus:ring-4 focus:outline-none focus:ring-gray-50"
        >
          <div *ngFor="let array of evaluationRatingConvertToArray(5)">
            <svg
              aria-hidden="true"
              [ngClass]="evaluationRatingConvertToArray(evaluation.attributes.rating)[array] == array ? 'text-yellow-400' : 'text-gray-300'"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              ></path>
            </svg>
          </div>
        </div>
      </footer>
      <p class="text-gray-500">
        {{ evaluation.attributes.comment }}
      </p>
    </article>
  </div>

</div>
