import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alert-window',
  templateUrl: './alert-window.component.html',
  styleUrls: ['./alert-window.component.scss']
})
export class AlertWindowComponent implements OnInit {

  @Input() textContent: string = '';
  @Input() confirmLabel: string = 'OK';
  @Input() loginLabel: string = 'SIGN-IN'
  @Input() title: string = 'Alert';
  @Input() hasLogginBtn: boolean = false;
  @Output() confirmBtnClick = new EventEmitter();
  @Output() confirmBtnLogin = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onConfirmClick() {
    this.confirmBtnClick.emit();
  }

  onLoginClick(){
    this.confirmBtnLogin.emit();
  }

}
