import { DynamicInputBase } from './dynamic-input-base';
import { IDynamicInputAOI } from '../../../../../../@core';

export class AreaOfInterestInput extends DynamicInputBase<string> {
  controlType = 'AOI';
  serviceFootprint?: string;

  constructor(options: IDynamicInputAOI = {}) {
    super(options);
    this.serviceFootprint = options.serviceFootprint;
  }
}
