<app-date-picker
   *ngIf="isVisible"
   (selectedDate)="onDateSelected($event)"
   [maxDate]="maxDate"
   [minDate]="minDate"
   [label]="input.label"
   [isDateRange]="false"
   [helperText]="input.helperText"
   [useMinDateAsDefault]="useMinDateAsDefaultMonth"
></app-date-picker>

<!-- <app-toi-picker [startDate]="input.serviceStartDate"
                [label]="input.label"
                [helperText]="input.helperText"
                [endDate]="input.serviceEndDate"
                [hasError]="hasError"
                [inputKey]="input.key"
                (selectedDate)="onDateSelected($event)"
></app-toi-picker> -->
