import {
  AoiMeasureUnits, Frequency, PriceModel, ServiceLimits, Subtype, ToiMeasureUnits
} from '@core/models';

export const aoiMeasureUnits = [
  {
    label: 'Hectare',
    value: AoiMeasureUnits.HA,
  },
  {
    label: 'Square Kilometer',
    value: AoiMeasureUnits.KM,
  },
  {
    label: 'Square meter',
    value: AoiMeasureUnits.SQM,
  },
];

export const toiMeasureUnits = [
  {
    label: 'Day',
    value: ToiMeasureUnits.DAY,
  },
  {
    label: 'Week',
    value: ToiMeasureUnits.WEEK,
  },
  {
    label: 'Month',
    value: ToiMeasureUnits.MONTH,
  },
  {
    label: 'Year',
    value: ToiMeasureUnits.YEAR,
  },
];

export const priceModel = [
  {
    label: 'Based on AOI',
    value: PriceModel.BASED_ON_AOI,
  },
  {
    label: 'Based on TOI',
    value: PriceModel.BASED_ON_TOI,
  },
  {
    label: 'Based on TOI and AOI',
    value: PriceModel.BASED_ON_TOI_AND_AOI,
  },
];

export const subtypes = [
  {
    label: 'Direct Download',
    value: Subtype.Direct_Download,
  },
  {
    label: 'WMS',
    value: Subtype.WMS,
  },
  {
    label: 'WFS',
    value: Subtype.WFS
  }
];

export const frequencies = [
  {
    label: 'Hourly',
    value: Frequency.HOURLY,
  },
  {
    label: 'Daily',
    value: Frequency.DAILY,
  },
  {
    label: 'Weekly',
    value: Frequency.WEEKLY,
  },
  {
    label: 'Monthly',
    value: Frequency.MONTHLY,
  },
  {
    label: 'Yearly',
    value: Frequency.YEARLY,
  },
];

export const serviceLimits = [
  {
    label: 'Limit by AOI',
    value: ServiceLimits.LIMIT_BY_AOI,
  },
  {
    label: 'Limit by TOI',
    value: ServiceLimits.LIMIT_BY_TOI,
  },
  {
    label: 'Limit by TOI and AOI',
    value: ServiceLimits.LIMIT_BY_TOI_AND_AOI,
  },
];

export const allowedGeometries = [
  {
    label: 'POLYGON',
    value: 'polygon'
  },
  {
    label: 'POINT',
    value: 'point'
  }
]
