import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-select-input',
  templateUrl: './custom-select-input.component.html',
  styleUrls: ['./custom-select-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSelectInputComponent),
      multi: true,
    },
  ],
})
export class CustomSelectInputComponent
  implements OnInit, ControlValueAccessor
{
  @Input() label: string;
  @Input() items: any = [];
  @Input() bindLabel: string = "label";
  @Input() bindValue: string;
  @Input() groupeBy: string | ((value: any) => any);
  @Input() placeholder!: string;

  @Input() required: boolean = false;
  @Input() searchable: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() markFirst: boolean = false;
  @Input() clearable: boolean = false;
  @Input() multiple: boolean = false;

  @Input() value?: number | string | boolean | Array<any> | {label: string, value: boolean | string};

  @Output() propagate = new EventEmitter();

  constructor() {}

  ngOnChanges(): void {
    if (this.isDisabled && this.items) {
      this.value = undefined;
      this.propagateSelected();
    }
  }

  registerOnTouched() {}

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  writeValue(value: any = null) {
    this.value = value;
  }

  private propagateChange = (_: any) => {};

  propagateSelected() {
    this.propagateChange(this.value);
    this.propagate.emit(this.value);
  }

  ngOnInit(): void {
  }

}
