import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import {faStar} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent implements OnInit {
  @Input() icon: IconDefinition = faStar;
  @Input() disabled!: boolean;
  @Input() type?: string;
  @Input() isDisabled: boolean = false;
  @Input() label?: string;
  @Input() textColor?: string;
  @Input() hover?: string;
  @Input() background?: string;
  @Input() iconNgClass: NgClass["ngClass"];
  @Input() iconSize: SizeProp = "1x";

  @Output() btnClick = new EventEmitter();
  class: string = '';

  constructor() {}

  ngOnInit(): void {}

  onClick() {
    this.btnClick.emit();
  }

  disabledClass() {
    return this.isDisabled
      ? 'opacity-30'
      : 'duration-300 motion-safe:hover:scale-95 motion-safe:active:scale-90 active:shadow-inner ease-in-out';
  }
}
