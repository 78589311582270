import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { httpOptions } from '@core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class BecomeSellerService {

    constructor(private httpService: HttpClient) {
    }

    requestBecomeSeller(): Observable<Object> {
        return this.httpService.post(`${environment.apiUrl}/v1/auth/become-seller`, {}, httpOptions);
    }


}
