import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

/**
 * @param label - propriety label
 * @param textData - propriety data
 * */
@Component({
  selector: 'app-propriety-wrapper',
  templateUrl: './propriety-wrapper.component.html',
  styleUrls: ['./propriety-wrapper.component.scss']
})
export class ProprietyWrapperComponent implements OnInit {

  @Input() label: string = 'Propriety label';
  @Input() textData?: string;
  @Input() organizationLogoURI?: string;
  @Input() textDataClass: NgClass["ngClass"];

  constructor() {
  }

  ngOnInit(): void {
  }

}
