import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { isStringEmpty } from 'shared';
import { InputComponent } from '..';

@Component({
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagInputComponent),
      multi: true,
    },
  ],
})
export class TagInputComponent implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild('appInput') appInput?: InputComponent;
  valueExists: boolean = false;
  faTimes = faTimes;
  tags: string[] = [];
  open: boolean = false;

  tagValue: string[] = [];

  @Input('regex-pattern') regexPattern?: string;
  @Input() required: boolean = false;
  @Input() tagPlaceholder?: string;
  @Input() value?: string;
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() id!: string;
  @Input() existingTags?: string[];
  @Input() inputType: string = 'text';
  @Input() inputStep: number = 1;

  constructor() {}

  onChange = (tags: string[]) => {};
  onTouched = () => {};

  writeValue(tags: string[]) {
    this.tagValue = tags;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.existingTags) {
      this.tags = this.existingTags;
    }
  }

  handleRemoveTag(index: number) {
    this.tags.splice(index, 1);
    this.onChange(this.tags);
  }

  handleKeyPress() {
    this.open = false;
    if (this.value) {
      if (!this.tags.includes(this.value)) {
        this.valueExists = false;
        this.tags.push(this.value);

        if (this.appInput) this.appInput.value = undefined;
        this.value = undefined;
      } else this.valueExists = true;
    }
    this.onChange(this.tags);
  }

  handleInputChange() {
    if (!isStringEmpty(this.value)) this.open = true;
    else this.open = false;
  }

  valueExistsClass() {
    return { 'focus:ring-red-500 focus:bg-red-50': this.valueExists };
  }
}
